<template>
	<span class="calculation-dot" :style="{ color: calculatedColor }" />
</template>

<script>
export default {
	props: {
		actual: {
			type: [String, Number],
		},
		suggested: {
			type: [String, Number],
		},
		thresholds: {
			type: Object,
			default: () => ({
				red: -0.15,
				yellow: 0,
			})
		},
		transparent: Boolean,
	},
	computed: {
		noData () {
			return [undefined, null, ''].some(val => this.actual === val || this.suggested === val)
		},
		calculation () {
			if (this.noData) { return }
			const actualMetric = Number(this.actual)
			const suggestedNumber = Number(this.suggested)
			return suggestedNumber
				? (actualMetric - suggestedNumber) / suggestedNumber
				: 0
		},
		calculatedColor () {
			if (this.transparent) {
				return 'transparent'
			}
			if (this.noData) {
				return 'gray'
			}
			if (this.calculation >= this.thresholds.yellow) {
				return '#6ECF93'
			}
			if (this.calculation >= this.thresholds.red) {
				return '#FFCC00'
			}
			return '#FF5851'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

$block: calculation-dot;

.#{$block} {
	$size: 0.5rem;

	display: inline-block;
	margin-right: 5px;
	width: $size;
	height: $size;
	border-radius: 50%;
	color: transparent;
	background-color: currentColor;
}
</style>
