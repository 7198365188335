<template>
	<DashCard
		title="The R.O.A.D. Metric"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: R.O.A.D. Metric"
		style="min-height:100%;"
		@reload="$emit('reload', 'advance_road_metric')"
	>
		<template #icon><Icon /></template>

		<div class="road-metric-card__label">{{ label }}</div>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/ROADMetricIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getROADMetricChartConfig } from '../../Charts/chart-configs'

const config = [
	{ key: 'base', label: 'Base', agencyLevel: 'Base' },
	{ key: 'direct', label: 'Direct', agencyLevel: 'Direct' },
	{ key: 'total', label: 'Total Agency', agencyLevel: 'Total' },
	{ key: 'top25', label: 'Top 25%', agencyLevel: 'Base' },
]
const metric = 'RTWsToCWs'

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		label () {
			return this.monthlyPeriod
				? 'Percentage of agents over the past 12 months who are still active as Consistent Writers'
				: 'Percentage of agents over the past 12 weeks who are still active as Consistent Writers'
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => this.checkNullish(item[metric]))
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				const { data } = this.data
				return config.map((item) => {
					const itemData = data.find((i) => {
						return item.key !== 'top25'
							? i.AgencyLevel === item.agencyLevel
							: i.AgencyLevel_Top25 === item.agencyLevel 
					})
					const itemValue = item.key !== 'top25'
						? (itemData?.[metric] ?? 0)
						: (itemData?.[`${metric}_Top25`] ?? 0)
					return { ...item, value: this.getValue(itemValue) }
				})
			}
			return config.map(item => ({ ...item, value: Math.round(Math.random() * 100) }))
		},
		chartConfig () {
			const data = this.computedData.map(({ label, value }) => ([label, value]))
			const maxValue = Math.max(...this.computedData.map(({ value }) => value))
			const yAxisTickPositions = this.getYAxisTickPositions(maxValue)
			const minorTickInterval = maxValue > 10
				? maxValue > 50 ? undefined : 5
				: 2.5
			const xAxisCategories = config.map(({ label }) => label)
			return getROADMetricChartConfig(data, xAxisCategories, yAxisTickPositions, minorTickInterval)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getValue (value) {
			if (this.checkNullish(value)) { return null }
			return Math.round(value * 100)
		},
		getYAxisTickPositions (maxValue) {
			const ceiledMaxValue = Math.ceil(maxValue / 10) * 10
			return Array(11).fill(1).reduce((acc, item, index) => {
				const tick = index * 10
				if (tick <= ceiledMaxValue) { acc = [...acc, tick] }
				return acc
			}, [])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.card-content {
	color: $color-text;
	margin-top: 0.75rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	.dash-chart {
		width: 100%;
	}
}

.road-metric-card__label {
	margin-top: 0.75rem;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.3;
}

::v-deep .dash-chart {
	$chart-font-size-large: 1rem;
	$chart-font-size-secondary: 0.8rem;

	.highcharts-tooltip-box {
		stroke: transparent;
		fill: transparent;
		fill-opacity: 1;
	}

	.chart-tooltip-wrapper {
		padding: 8px;
		border-radius: 4px;
		background-color: #031036d0;
	}

	.chart-tooltip-header {
		margin: 0;
		padding: 0.2em;
		color: $color-text;
		font-size: $chart-font-size-secondary;
		border-bottom: 1px solid currentColor;
		font-weight: bold;
	}

	.chart-tooltip-point {
		margin: 0;
		padding: 0.2em;
		color: $color-text;
		font-size: $chart-font-size-large;
		font-weight: bold;
	}
}
</style>
