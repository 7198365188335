<template>
	<DashCard
		:title="cardTitle"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Production Tracking"
		@reload="$emit('reload', 'activate_production_tracking')"
	>
		<template #icon><ProductionTrackingIcon /></template>

		<div class="card-content" :class="isMobileView && 'card-content--mobile'">
			<div class="content-table__row">
				<div v-for="data in computedData" :key="data[0].key" class="content-table__col">
					<v-data-table
						:headers="headers"
						:items="data"
						item-key="key"
						mobile-breakpoint="0"
						class="card-table"
						dark hide-default-footer hide-default-header disable-sort
					>
						<template #item.metric="{ item }">
							<div><strong>{{ item.metric }}</strong></div>
						</template>
						<template #item.value="{ item }">
							<span class="nowrap" :class="item.comparable && 'font-weight-bold'">
								<CalculationDot :transparent="!item.comparable" :actual="item.value" :suggested="item.suggested" />
								<span v-if="item.value < 0">-</span>
								<span v-if="item.type === 'currency'" class="with-currency">{{ formatMoney(item.value) }}</span>
								<span v-else-if="item.type === 'percent'" class="nowrap">{{ formatRatio(item.value) }}%</span>
							</span>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import ProductionTrackingIcon from '../../Icons/ProductionTrackingIcon.vue'
import CalculationDot from '../../Components/CalculationDot.vue'

const config = [
	{ key: 'APVSubmit', metric: 'APV Submit', type: 'currency', comparable: true, position: 1 },
	{ key: 'Placed', metric: 'Placed', type: 'currency', comparable: true, position: 1 },
	{ key: 'APVPerApp', metric: 'APV Per\u00A0App', type: 'currency', position: 1 },
	{ key: 'Reversals', metric: 'Reversals', type: 'currency', position: 1 },
	{ key: 'ReversalRate', metric: 'Reversal Rate', type: 'percent', position: 2 },
	{ key: 'IssueRate', metric: 'Issue Rate', type: 'percent', comparable: true, position: 2 },
	{ key: 'PPL', metric: 'PPL', type: 'currency', comparable: true, position: 2 },
	{ key: 'APVPerLead', metric: 'APV Per Lead', type: 'currency', position: 2 },
]

export default {
    components: {
		DashCard,
		ProductionTrackingIcon,
		CalculationDot,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		headers () {
			return [{
                text: 'Metric',
                value: 'metric',
            }, {
                text: 'Value',
                value: 'value',
			}]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			const data = this.data?.data || {}
			const suggested = this.data?.suggested || {}
			const cols = this.isMobileView
				? [1]
				: [...new Set(config.map(item => item.position))].sort((a, b) => a - b)
			return cols.map(pos => config
				.filter(item => this.isMobileView ? true : item.position === pos)
				.map(({ position, ...item }) => {
					const value = item.key !== 'APVPerLead'
						? data[item.key]
						: this.monthlyPeriod ? data[`${item.key}_Monthly`] : data[`${item.key}_Weekly`]
					return {
						...item,
						value,
						suggested: this.getSuggested(item.key, data, suggested),
					}
				})
			)
		},
		cardTitle () {
			const month = this.data?.data?.MonthName
			const isNullish = [undefined, null, ''].includes(month)
			if (isNullish) { return 'Production Tracking' }
			return `Production Tracking: ${month[0].toUpperCase()}${month.slice(1)}`
		},
    },
	methods: {
		getSuggested(key, data, suggested) {
			switch (key) {
				case 'APVSubmit':
					return suggested.neededSubmitProductionTracking
				case 'Placed':
					return suggested.neededPlacedProductionTracking
				case 'IssueRate':
					return data.IssueRateTarget || suggested.issueRate
				case 'PPL':
					return data.PPLTarget
				default:
					return undefined
			}
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0.00')
		},
		formatRatio (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(100 * Math.abs(value), '0[.]0')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	--td-padding: 0 0.5rem;

	&--mobile {
		--td-padding: 0 0.75rem;
	}

	.content-table {
		&__row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			row-gap: 0;
			column-gap: 0;
		}

		&__col {
			max-width: 100%;
			flex: 1 1 13rem;
		}
	}

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep tbody tr td {
			height: 42px;
			border-bottom: $border-bottom-td;
			padding: var(--td-padding);

			div {
				display: flex;
				align-items: center;
				color: $color-text;
				line-height: 1.2;
			}

			&:first-child {
				background-color: $color-water-green-20;
			}

			&:last-child {
				width: 7.5rem;
			}
		}
	}
}
</style>
