<template>
	<div class="trends-dropdown" :class="computedClass" :id="componentId">
		<v-menu
			v-model="menu"
			v-bind="bindProps"
		>
			<template #activator="{ on, attrs }">
				<v-btn
					block
					text
					color="#46C3B2"
					class="trends-dropdown__btn"
					:disabled="loading"
					v-bind="attrs"
					v-on="on"
				>
					<span>{{ label }}</span>
					<v-icon class="trends-dropdown__btn-icon">{{ activatorIcon }}</v-icon>
				</v-btn>
				<div class="trends-dropdown__divider-container">
					<v-progress-linear v-if="loading" :height="2" indeterminate />
					<v-divider v-else class="trends-dropdown__divider" />
				</div>
			</template>

			<v-card dark class="trends-dropdown__content-card with-scroll">
				<div v-if="hasError" class="trends-dropdown__content-error">
					<div>An error occurred while loading data. You can reload the whole page or just this data</div>
					<v-btn
						class="error-btn mt-2" elevation="0"
						:small="isMobileView"
						color="q_blue_1"
						@click="$emit('reload')"
					>
						<v-icon>fas fa-sync-alt</v-icon>
						<v-divider class="mx-2" color="white" vertical />
						Reload Data
					</v-btn>
				</div>

				<div v-else class="trends-dropdown__table-content">
					<div v-if="noLoadedData" class="trends-dropdown__table-content-no-data">No data available</div>
					<table class="advance-simple-table trends-dropdown__table">
						<thead>
							<tr>
								<th />
								<th>Base</th>
								<th>Direct</th>
								<th>Total</th>
								<th>Top<br>25%</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in computedData" :key="item.key">
								<template v-if="item.type === 'row'">
									<td class="advance-simple-table__metric-row" colspan="5" v-html="item.label" />
								</template>
								<template v-else>
									<td class="advance-simple-table__metric-col" v-html="item.label" />
									<td class="text-left">{{ item.Base }}</td>
									<td class="text-left">{{ item.Direct }}</td>
									<td class="text-left">{{ item.Total }}</td>
									<td class="text-left">{{ item.Top25 }}</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { agencyLevels } from '../../config/common'
import { metrics, trendsDropdownConfigs } from '../../config/trends'

export default {
	props: {
		data: { type: Object, default: null },
		metric: { type: String, default: metrics.Recruiting },
		label: { type: String, default: 'Activity' },
		contentClass: { type: String },
	},
	data () {
		return {
			menu: false,
			maxWidth: '100%',
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.menu && 'trends-dropdown--opened',
			]
		},
		computedContentClass () {
			return [
				'trends-dropdown__menu-content',
				(this.hasError) && 'trends-dropdown__menu-content--restricted',
				this.isMobileView && 'trends-dropdown__menu-content--mobile',
				this.noLoadedData && 'trends-dropdown__menu-content--no-data',
				this.contentClass,
			].filter(Boolean).join(' ')
		},
		activatorIcon () {
			return this.menu ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
		},
		config () {
			return trendsDropdownConfigs[this.metric]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => {
					if (Array.isArray(item)) {
						if (!item.length) { return true }
						return item.every(this.checkNullish)
					}
					return Object.values(item).every(this.checkNullish)
				})
			}
			return false
		},
		computedData () {
			let data = []
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			return this.getData(data)
		},
		componentId () {
			return `trendsDropdown-${this.metric}`
		},
		bindProps () {
			return {
				bottom: true,
				offsetY: true,
				nudgeTop: -2,
				zIndex: 9,
				attach: (this.hasError) ? `#${this.componentId}` : false,
				contentClass: this.computedContentClass,
			}
		},
	},
	watch: {
		loading () {
			if (this.loading) {
				this.menu = false
			}
		},
	},
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getData (data) {
			const dataByLevels = this.getLevelsData(data)
			return this.config.map((item) => ({
				...item,
				Base: this.formatItem(dataByLevels.Base[`Base_${item.key}`], item.type),
				Direct: this.formatItem(dataByLevels.Direct[`Direct_${item.key}`], item.type),
				Total: this.formatItem(dataByLevels.Total[`Total_${item.key}`], item.type),
				Top25: this.formatItem(dataByLevels.Top25[`Top25_${item.key}`], item.type),
			}))
		},
		getLevelsData (data) {
			const levels = [...Object.values(agencyLevels), 'Top25']
			return levels.reduce((acc, level) => {
				acc[level] = data.find((item) => Object.keys(item).some((key) => key.startsWith(level))) ?? {}
				return acc
			}, {})
		},
		formatItem (value, type) {
			const valueSign = value < 0 ? '-' : ''
			const isNullish = this.checkNullish(value)
			if (type === 'currency') {
				return isNullish
					? 'NA'
					: valueSign + this.formatNumber(Math.abs(value), '0,0')
			}
			if (type === 'percent') {
				return isNullish
					? 'NA%'
					: valueSign + this.formatNumber(Math.abs(value) * 100, '0') + '%'
			}
			if (isNullish) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.trends-dropdown {
	position: relative;
	padding: 0.75rem 0.5rem;
	color: $color-water-green;
	line-height: 1;

	.trends-dropdown__btn {
		padding: 0 0.5rem !important;
		font-weight: 700;
		letter-spacing: normal;
		text-transform: none;

		::v-deep .v-btn__content {
			display: inline-flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
		}

		.trends-dropdown__btn-icon {
			margin-left: 1rem;
			color: $color-divider;
		}

		&.v-btn--disabled {
			opacity: 0.6;
			color: $color-water-green !important;

			.trends-dropdown__btn-icon {
				color: $color-divider !important;
			}
		}
	}
	
	.trends-dropdown__divider-container {
		height: 2px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	
	.trends-dropdown__divider {
		border-color: $color-divider;
		border-top-width: 1px;
	}

	&--opened {
		.trends-dropdown__divider {
			border-color: $color-text;
		}
	}
}

.trends-dropdown__menu-content {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: 1px solid $color-card-dark-2;
}

.trends-dropdown__menu-content--restricted {
	width: calc(100% - 1rem);
}

.trends-dropdown__content-card {
	width: 100%;
	background-color: $color-card-dark !important;
	color: $color-text !important;
	overflow-x: auto;
}

.trends-dropdown__table-content {
	position: relative;
}

.trends-dropdown__table-content-no-data {
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 600;
	z-index: 1;
}

.trends-dropdown__table {
	min-width: 100%;
}

.trends-dropdown__menu-content--no-data {
	.trends-dropdown__table {
		opacity: 0.2;
	}
}

.trends-dropdown__content-error {
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ff5252;
	text-align: center;
	line-height: 1.5;

	.error-btn {
		border-radius: 0.2rem;
		font-weight: 600;

		&:not(.v-btn--text, .v-btn--icon) {
			color: $color-text;
		}
	}
}
</style>
