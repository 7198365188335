export const agencyLevels = {
	Base: 'Base',
	Direct: 'Direct',
	Total: 'Total',
}

export const agencyLevelsConfig = [
	{ value: agencyLevels.Base, text: 'Base' },
	{ value: agencyLevels.Direct, text: 'Direct' },
	{ value: agencyLevels.Total, text: 'Total Agency' },
]

export const statType = {
	Personal: 'Personal',
	TotalAgency: 'Total',
}
