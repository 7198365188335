<template>
	<DashCard
		title="Recruiting and Onboarding"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Recruiting and Onboarding"
		style="min-height:100%;"
		@reload="$emit('reload', 'accelerate_recruiting_onboarding')"
	>
		<template #icon><Icon /></template>

		<div class="card-content" :class="computedClass">
			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="key"
				mobile-breakpoint="0"
				class="card-table"
				dark hide-default-footer hide-default-header disable-sort
			>
				<template #item.metric="{ item }">
					<div v-if="item.key === 'interviews'" class="field-name">
						<v-progress-circular v-if="interviews.saving" class="field-loader" color="#46C3B2" size="10" width="1" indeterminate />
						<span class="nowrap"><strong>{{ item.metric }}</strong></span>
					</div>
					<span v-else class="nowrap"><strong>{{ item.metric }}</strong></span>
				</template>
				<template #item.value="{ item }">
					<div v-if="item.key === 'interviews' && isAllowEditing && !noLoadedData" class="item-field field-amount">
						<v-text-field
							:value="interviews.value"
							dense hide-details dark color="#46C3B2"
							class="mt-0 pt-0"
							@input="onInput"
						>
							<template #append>
								<v-icon>icon-edit-q</v-icon>
							</template>
						</v-text-field>
					</div>
					<span v-else class="card-table__value-item">{{ formatValue(item.value) }}</span>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI'
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/ActivityReportIcon.vue'

const config = [
	{ key: 'interviews', metric: 'Interviews Conducted' },
	{ key: 'invites_sent', metric: 'Invites Sent' },
	{ key: 'invites_accepted', metric: 'Invites Accepted' },
	{ key: 'rtws', metric: 'RTW\'s' },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		agent: {
			type: Object,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
			interviews: {
				value: undefined,
				saving: false,
			},
		}
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		headers () {
			return [{
                text: 'Metric',
                value: 'metric',
            }, {
                text: 'Value',
                value: 'value',
			}]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				const { data } = this.data
				const monthly = config.map(item => ({ ...item, value: data[`${item.key}_monthly`] }))
				const weekly = config.map(item => ({ ...item, value: data[`${item.key}_weekly`] }))
				return this.monthlyPeriod ? monthly : weekly
			}
			return config.map(item => ({ ...item, value: '' }))
		},
		computedClass () {
			return [
				this.isMobileView && 'card-content--mobile',
			]
		},
		agentCode () {
			return this.agent?.AgentCode || this.user.AgentCode
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
    },
	watch: {
		'data.data': {
			immediate: true,
			handler () {
				this.updateInterviews()
			},
		},
		monthlyPeriod () {
			this.updateInterviews()
		},
	},
	methods: {
		updateInterviews () {
			if (!this.data?.data) { return }
			this.interviews.value = this.monthlyPeriod
				? this.data.data.interviews_monthly
				: this.data.data.interviews_weekly
		},
		saveInterviews: debounce(
			async function () {
				const { value } = this.interviews
				if (!this.isAllowEditing) { return }
				if (!this.validateInterviews(value)) { return }
				if (this.interviews.saving) { return }
				this.interviews.saving = true
				const interviewConducted = this.checkNullish(value) ? 0 : Number(value)
				const period = this.monthlyPeriod ? 'Monthly' : 'Weekly'
				const res = await QuilityAPI.updateInterviewsConducted(this.agentCode, period, { interviewConducted })
				if (res.error) {
					this.showError(`Oops! There was a problem submitting your request.<br>`, res.message)
					console.error(res.error)
					this.updateInterviews()
				} else {
					this.$emit('local-update', 'accelerate_recruiting_onboarding', res.data)
				}
				this.interviews.saving = false
			},
			2000
		),
		validateInterviews (value) {
			if (!this.data?.data) { return false }
			const interviews = this.monthlyPeriod
				? this.data.data.interviews_monthly
				: this.data.data.interviews_weekly
			return value !== interviews
		},
		onInput (value) {
			if (!this.isAllowEditing) { return }
			const processed = this.processNumber(value)
			this.interviews.value = processed
			this.saveInterviews()
		},
		processNumber (value) {
			const processed = [...String(value)]
				.filter((char) => char !== ' ' && !Number.isNaN(Number(char)))
				.join('')
			return processed === '' ? undefined : Number(processed)
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatValue (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	--td-padding: 0 1rem;
	--td-value-padding: 0 0.75rem;

	&--mobile {
		--td-padding: 0 0.5rem;
		--td-value-padding: 0 0.25rem;
	}

	.card-table {
		$border-bottom: 1px solid $color-border !important;

		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep tbody tr td {
			height: 36px;
			border-bottom: $border-bottom;
			
			&:first-child {
				width: 8rem;
				padding: var(--td-padding);
				background-color: $color-water-green-20;
			}

			&:last-child {
				padding: var(--td-value-padding);
			}
		}

		&__value-item {
			padding-left: 6px;
		}
	}

	.item-field {
		border-radius: $field-border-radius;
		background-color: $color-water-green-20;
		align-items: center;
		padding: 0 0 0 6px;

		::v-deep input {
			font-size: 0.875rem;
			font-weight: 600;
			padding-top: 2px !important;
		}

		::v-deep .v-input__append-inner {
			margin: 0 0 0 auto;
			align-self: center;

			.v-icon {
				color: #ffffff54;
				font-size: 16px !important;
			}
		}

		::v-deep .v-input__slot::before {
			border-color: transparent !important;
			border-image: none;
		}

		::v-deep .v-input__slot::after {
			border-color: transparent;
		}
	}

	.field-name {
		position: relative;
	}

	.field-amount {
		max-width: 5.0rem;
	}

	.field-loader {
		position: absolute;
		right: -12px;
		top: 0;
	}
}
</style>
