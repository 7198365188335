<template>
	<div
		v-if="isMobileView || isTabletView"
		class="perform-dash-title perform-dash-title_expandable"
		:class="[computedClass, computedDoShowTabs && 'perform-dash-title_with-bg']"
	>
		<div class="perform-dash-title__logo">
			<PerformDashIcon class="perform-dash-title__icon" />
			<h1 class="perform-dash-title__main">PerformDash</h1>
			<v-btn dark icon @click="computedDoShowTabs = !computedDoShowTabs">
				<v-icon>{{ computedDoShowTabs ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
			</v-btn>
		</div>
		<DashboardTabs
			v-if="computedDoShowTabs"
			v-model="computedValue"
			:tabs="tabs"
			separate
			@click-lock="$emit('click-lock', $event)"
		/>
	</div>
	<div v-else class="perform-dash-title perform-dash-title_opened" :class="computedClass">
		<div class="perform-dash-title__logo">
			<PerformDashIcon class="perform-dash-title__icon" />
			<h1 class="perform-dash-title__main">PerformDash</h1>
		</div>
		<DashboardTabs
			v-model="computedValue"
			:tabs="tabs"
			class="ml-4"
			@click-lock="$emit('click-lock', $event)"
		/>
	</div>
</template>

<script>
import LockIcon from '../Icons/LockIcon.vue'
import PerformDashIcon from '../Icons/PerformDashIcon.vue'
import DashboardTabs from './DashboardTabs.vue'

export default {
	components: {
		PerformDashIcon,
		LockIcon,
		DashboardTabs,
	},
	props: {
		value: { type: Number, default: 0 },
		tabs: { type: Array, default: () => [] },
		doShowTabs: { type: Boolean, default: false },
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		computedDoShowTabs: {
			get () { return this.doShowTabs },
			set (v) { this.$emit('update:doShowTabs', v) },
		},
		computedClass () {
			return [
				this.isMobileView && 'perform-dash-title--mobile',
				this.isTabletView && 'perform-dash-title--tablet',
			]
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

$block: perform-dash-title;

.#{$block} {
	--font-title: 2rem;

	&--mobile {
		--font-title: 1.5rem;
	}

	padding: var(--header-padding);
	padding-top: 2rem;
	display: flex;
	transition: background-color 0.2s;

	&_opened {
		align-items: center;
		flex-grow: 10;
	}

	&_expandable {
		width: 100%;
		flex-direction: column;
		row-gap: 1rem;
	}

	&_with-bg {
		background-color: var(--card-bg);
		padding-bottom: 1.5rem;
	}

	&__logo {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-size: var(--font-title);
		gap: 0.625rem;
	}

	&__icon {
		flex-shrink: 0;
	}

	&__main {
		margin-bottom: 0;
		color: $color-water-green;
		font-size: inherit;
		line-height: 1;
		font-weight: bold !important;
		word-break: normal;
	}
}
</style>
