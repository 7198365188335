<template>
	<v-tabs
		v-if="tabs.length"
		v-model="computedValue"
		:optional="computedValue === -1"
		background-color="transparent" dark
		slider-color="#46C3B2" slider-size="4"
		height="42px"
		class="perform-dash__dashboard-tabs"
		:class="isMobileView && 'perform-dash__dashboard-tabs--mobile'"
	>
		<template v-for="(item, idx) in tabs">
			<v-tab
				v-if="!item.disabled"
				:key="item.title"
				class="tabs__item"
			>
				<div class="tabs__item-content" :class="(separate && idx === 0) && 'tabs__item-content_not-bordered'">
					<p class="tabs__item-label">phase {{ item.phase }}</p>
					<p class="tabs__item-title">{{ item.title }}</p>
				</div>
			</v-tab>
			<div
				v-else
				:key="item.title"
				class="tabs__item-disabled"
			>
				<v-btn class="tabs__item-disabled-button" icon @click="$emit('click-lock', item)">
					<LockIcon />
				</v-btn>
				<div class="tabs__item-content">
					<p class="tabs__item-label">phase {{ item.phase }}</p>
					<p class="tabs__item-title">{{ item.title }}</p>
				</div>
			</div>
		</template>
	</v-tabs>
</template>

<script>
import LockIcon from '../Icons/LockIcon.vue'

export default {
	components: { LockIcon },
	props: {
		value: { type: Number, default: -1 },
		tabs: { type: Array, default: () => [] },
		separate: { type: Boolean, default: false },
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedValue: {
			get () {
				const value = this.mapValue()
				return value
			},
			set (v) {
				const value = this.mapComputedValue(v)
				this.$emit('input', value)
			},
		},
	},
	methods: {
		mapValue () {
			if (this.value !== 0) {
				let value = this.value
				this.tabs
					.filter((item) => item.disabled)
					.map((item) => item.phase - 1)
					.reverse()
					.forEach((index) => {
						if (index < value) { value -= 1 }
					})
				return value
			} else {
				return this.value
			}
		},
		mapComputedValue (val) {
			if (val !== 0) {
				let value = val
				this.tabs
					.filter((item) => item.disabled)
					.map((item) => item.phase - 1)
					.forEach((index) => {
						if (index <= value) { value += 1 }
					})
				return value
			} else {
				return val
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

.perform-dash__dashboard-tabs {
	--font-size: 1.25rem;
	
	&--mobile {
		--font-size: 1rem;
	}

	.tabs__item {
		padding: 0;
	}

	.tabs__item-disabled {
		position: relative;
		padding-bottom: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #000000;
	}

	.tabs__item-disabled-button {
		position: absolute;
		z-index: 1;
	}

	.tabs__item-content {
		padding: 0 1rem;
		border-left: 1px solid currentColor;
	}

	.tabs__item-content_not-bordered {
		border-left: none;
	}

	.tabs__item-label {
		margin-bottom: 2px;
		font-size: 0.625rem;
		line-height: 1;
		text-align: left;
	}

	.tabs__item-title {
		margin-bottom: 0;
		font-size: var(--font-size);
		line-height: 1;
		text-align: left;
		text-transform: none;
	}

	.v-tab {
		padding-bottom: 6px;
	}

	.v-tab--active {
		background-color: transparent;
	}

	::v-deep .v-tabs-bar {
		.v-tabs-slider-wrapper {
			padding: 0 1rem;
		}
		.v-slide-group__prev,
		.v-slide-group__next {
			flex-basis: 42px;
			min-width: 42px;
		}
	}
}
</style>
