<template>
	<DashCard
		:title="cardTitle"
		:loading="loading"
		silent-loading
		:has-error="hasError"
		:info-data="infoData"
		info-data-title="External Lead Sources"
		:updated-on="updatedOn"
		@reload="$emit('reload', 'activate_external_lead')"
	>
		<template #icon><LeadsOverviewIcon /></template>

		<div class="card-content">
			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="ID"
				mobile-breakpoint="0"
				class="card-table with-scroll"
				dark hide-default-footer disable-sort
			>
				<template #item="{ item }">
					<tr v-if="!checkNullish(item.ID)">
						<td><div class="block-ellipsis">{{ item.LeadSource }}</div></td>
						<td>{{ item.LeadPurchase }}</td>
						<td>
							<span class="nowrap">
								<span v-if="item.LeadCost < 0">-</span>
								<span class="with-currency">{{ formatMoney(item.LeadCost) }}</span>
							</span>
						</td>
						<td>{{ item.CloseRatio }}%</td>
						<td v-if="isAllowEditing" class="table-actions-td">
							<div class="table-actions">
								<v-btn color="#ffffff54" small fab icon :disabled="loading || hasError" @click.stop="processLead(item)">
									<v-icon>icon-edit-q</v-icon>
								</v-btn>
							</div>
						</td>
					</tr>
					<tr v-else>
						<td><div class="block-ellipsis field-placeholder">Add lead source</div></td>
						<td colspan="3"><div class="field-placeholder">Click the pencil icon to enter this data</div></td>
						<td v-if="isAllowEditing" class="table-actions-td">
							<div class="table-actions">
								<v-btn color="#ffffff54" small fab icon :disabled="loading || hasError" @click.stop="processLead(item)">
									<v-icon>icon-edit-q</v-icon>
								</v-btn>
							</div>
						</td>
					</tr>
				</template>
			</v-data-table>
		</div>

		<ExternalLeadSourcesDialog
			v-if="doDialog && dialogMode"
			v-model="doDialog"
			:item="currentItem"
			:mode="dialogMode"
			:monthly-period="monthlyPeriod"
			@save="$emit('reload', 'activate_external_lead')"
		/>
	</DashCard>
</template>

<script>
import moment from 'moment'

import DashCard from '../DashCard.vue'
import ExternalLeadSourcesDialog from '../../Components/ExternalLeadSourcesDialog.vue'
import LeadsOverviewIcon from '../../Icons/LeadsOverviewIcon.vue'

const serverDateFormat = 'YYYY-MM-DD'
const dateFormat = 'MM/DD/YYYY'

const defaultSources = new Array(10).fill(null).map(() => ({
	LeadSource: null,
	LeadPurchase: null,
	LeadCost: null,
	CloseRatio: null,
}))

const getPeriodData = (data, period) => {
	return data.map((item) => {
		item.LeadCost_Weekly = Number(item.LeadCost_Weekly)
		item.LeadCost_Monthly = Number(item.LeadCost_Monthly)
		item.LeadPurchase_Weekly = Number(item.LeadPurchase_Weekly)
		item.LeadPurchase_Monthly = Number(item.LeadPurchase_Monthly)
		item.CloseRatio = Number(item.CloseRatio)
		const isNewWeek = moment().isAfter(item.BusinessWeekEnd, serverDateFormat)
		const LeadCost = period === 'Monthly'
			? item.LeadCost_Monthly
			: isNewWeek ? 0 : item.LeadCost_Weekly
		const LeadPurchase = period === 'Monthly'
			? item.LeadPurchase_Monthly
			: isNewWeek ? 0 : item.LeadPurchase_Weekly
		return { ...item, LeadCost, LeadPurchase }
	})
}

export default {
    components: {
		DashCard,
		LeadsOverviewIcon,
		ExternalLeadSourcesDialog,
	},
	props: {
		items: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		agent: {
			type: String,
		},
		infoData: Object,
	},
	data () {
		return {
			doDialog: false,
			currentItem: null,
			dialogMode: undefined, // new || edit
		}
	},
    computed: {
		headers () {
			return [{
                text: 'Lead Source',
                value: 'LeadSource',
				class: 'header-lead-source',
            }, {
                text: '#\u00A0of\u00A0Leads Purchased',
                value: 'LeadPurchase',
				class: 'header-lead-purchase',
            }, {
                text: 'Lead Cost',
                value: 'LeadCost',
				class: 'header-lead-cost',
            }, {
                text: 'Close Ratio',
                value: 'CloseRatio',
				class: 'header-close-ratio',
			},
			this.isAllowEditing && {
                text: '',
                value: 'tableActions',
				width: '2.5rem',
            }].filter(Boolean)
		},
		cardTitle () {
			return this.monthlyPeriod
				? 'Monthly External Lead Sources'
				: 'Weekly External Lead Sources'
		},
		loading () {
			if (!this.items) { return false }
			return this.items.loading
		},
		hasError () {
			if (!this.items) { return false }
			return this.items.error
		},
		computedData () {
			if (!this.items?.data) {
				return this.isAllowEditing ? [...defaultSources] : []
			}
			const monthly = getPeriodData(this.items.data, 'Monthly')
			const weekly = getPeriodData(this.items.data, 'Weekly')
			const data = this.monthlyPeriod ? monthly : weekly
			if (this.isAllowEditing) {
				return defaultSources.map((item, idx) => data[idx] ? data[idx] : { ...item })
			} else {
				return data
			}
		},
		updatedOn () {
			if (!this.items?.data?.length ) { return undefined }
			const date = this.computedData[0].LastChangeDate
			return moment(date, serverDateFormat).format(dateFormat)
		},
		agentCode () {
			return this.agent ?? this.user.AgentCode
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
    },
	watch: {
		doDialog (v) {
			if (!v) {
				this.dialogMode = undefined
				this.currentItem = null
			}
		},
	},
	methods: {
		processLead (item) {
			if (!this.isAllowEditing) { return }
			if (item.ID) {
				this.editLead(item)
			} else {
				this.createLead()
			}
		},
		createLead () {
			if (!this.isAllowEditing) { return }
			this.dialogMode = 'new'
			this.currentItem = null
			this.doDialog = true
		},
		editLead (item) {
			if (!this.isAllowEditing) { return }
			this.currentItem = item
			this.dialogMode = 'edit'
			this.doDialog = true
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return null }
			return this.formatNumber(Math.abs(value), '0,0.00')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$border-bottom: 1px solid $color-border !important;
$border-bottom-header: 3px solid $color-border !important;

.#{$block} {
	--td-padding: 0 0.5rem;

	color: $color-text;

	&__add-new {
		padding: 0.25rem 0;
		text-align: right;
		border-bottom: $border-bottom-td;
	}

	&__add-new-btn {
		text-transform: none;
		padding: 0 0.5rem !important;
	}

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep .v-data-table__wrapper {
			max-height: 168px;
			overflow: auto;
			padding-right: 5px;

			&::-webkit-scrollbar {
				width: $scrollbar-width-chrome;
				height: $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 16px $color-dark;
				border-radius: 2 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-water-green;
				border-radius: 0.5 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-corner {
				background: transparent;
			}
		}

		.table-actions-td {
			padding: 0;
		}

		.table-actions {
			white-space: nowrap;
			text-align: end;
		}

		&::v-deep tbody tr:hover {
			background: none !important;
		}

		&::v-deep .v-data-table-header {
			position: sticky;
			top: 0;
			background-color: var(--card-bg);
			z-index: 1;

			th {
				position: sticky;
				top: 0;
				background-color: var(--card-bg);
				z-index: 1;
			}
		}

		&::v-deep .v-data-table-header tr th {
			border-bottom: $border-bottom-th;
			padding: var(--td-padding);
			height: 42px !important;
			line-height: 1.3;

			&.header-lead-source {
				min-width: 8rem;
			}
			&.header-lead-purchase {
				min-width: 5rem;
			}
			&.header-lead-cost {
				min-width: 5rem;
			}
			&.header-close-ratio {
				max-width: 4rem;
			}

			span {
				display: inline-block;
				color: $color-text;
				font-weight: bold;
				max-width: min-content;
			}
		}

		&::v-deep tbody tr td {
			height: 42px;
			border-bottom: $border-bottom-td;
			padding: var(--td-padding);
			line-height: 1.3;

			&:first-child {
				background-color: $color-water-green-20;
			}
		}
	}

	.field-placeholder {
		opacity: 0.4;
		font-size: 0.9em !important;
	}

	.block-ellipsis {
		display: block;
		display: -webkit-box;
		max-width: 100%;
		max-height: 42px;
		font-size: 14px;
		line-height: 1.3;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
