<template>
	<DashCard
		title="Counting What Counts"
		:loading="loading"
		silent-loading
		:has-error="hasError"
		:info-data="infoData"
		:updated-on="updatedOn"
		@reload="$emit('reload', 'activate_activity_tracker')"
	>
		<template #icon><WeeklyActivityTrackerIcon /></template>

		<div class="card-content">
			<div class="sections-row with-scroll">
				<div v-for="(col, colIndex) in activitiesCols" :key="colIndex" class="sections-col">
					<template v-for="(i, itemIdx) in col">
						<div v-if="typeof i === 'number'" :key="i" class="sections-item">
							<div class="sections-item__field field-name">
								<v-progress-circular v-if="mData[i].saving" class="field-loader" color="#46C3B2" size="10" width="1" indeterminate />
								<v-text-field
									v-model.trim="mData[i].ActivityName"
									:readonly="mData[i].predefined || !isAllowEditing"
									:disabled="!mData[i].ActivityName && !isAllowEditing"
									dense hide-details dark color="#46C3B2"
									class="mt-0 pt-0"
									:class="mData[i].ActivityName && 'field-underlined'"
									@input="save(i)"
								>
									<template #append>
										<v-icon v-if="!mData[i].ActivityName">icon-edit-q</v-icon>
									</template>
								</v-text-field>
							</div>
							<div class="sections-item__field field-amount">
								<v-text-field
									ref="inputField"
									:key="`${mData[i].index}-${colIndex}-${itemIdx}-${mData[i].inputKey}`"
									:value="mData[i].ActivityAmount"
									:readonly="!isAllowEditing || isNotManual(mData[i].ActivityName)"
									:disabled="checkDisabledAmount(mData[i])"
									dense hide-details dark color="#46C3B2"
									class="mt-0 pt-0"
									:class="!checkNullish(mData[i].ActivityAmount) && 'field-underlined'"
									@input="onInput($event, i, `${colIndex}-${itemIdx}`)"
								>
									<template #append>
										<v-icon v-if="!isNotManual(mData[i].ActivityName) && checkNullish(mData[i].ActivityAmount)">icon-edit-q</v-icon>
									</template>
								</v-text-field>
							</div>
						</div>

						<div v-else :key="String(i)" class="sections-block">
							<template v-for="ix in i">
								<div v-if="typeof ix === 'number'" :key="ix" class="sections-item">
									<div class="sections-item__field bordered-field field-name">
										<v-progress-circular v-if="mData[ix].saving" class="field-loader" color="#46C3B2" size="10" width="1" indeterminate />
										<v-text-field
											:value="mData[ix].ActivityName"
											readonly
											dense hide-details dark color="#46C3B2"
											class="mt-0 pt-0 field-underlined field-underlined-focused"
										/>
									</div>
									<div class="sections-item__field bordered-field field-amount">
										<v-text-field
											ref="inputField"
											:key="`${mData[ix].index}-${colIndex}-${itemIdx}-${mData[ix].inputKey}`"
											:value="mData[ix].ActivityAmount"
											:readonly="!isAllowEditing"
											:disabled="checkDisabledAmount(mData[ix])"
											dense hide-details dark color="#46C3B2"
											class="mt-0 pt-0 field-underlined field-underlined-focused"
											@input="onInput($event, ix, `${colIndex}-${itemIdx}`)"
										>
											<template #append>
												<v-icon v-if="checkNullish(mData[ix].ActivityAmount)">icon-edit-q</v-icon>
											</template>
										</v-text-field>
									</div>
								</div>
								<div v-else :key="String(ix)" class="sections-item">
									<div class="sections-item__field field-name field-constant">
										{{ ix }}
									</div>
									<div class="sections-item__field field-amount field-constant">
										{{ calculateRatio(i) }}
									</div>
								</div>
							</template>
						</div>
					</template>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import moment from 'moment'
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI'
import DashCard from '../DashCard.vue'
import WeeklyActivityTrackerIcon from '../../Icons/WeeklyActivityTrackerIcon.vue'

const serverDateFormat = 'YYYY-MM-DD'
const dateFormat = 'MM/DD/YYYY'

const predefinedItems = [
	'Leads Received',
	'Contacts',
	'Appointments Booked',
	'Appointments Sat',
	'APV Submitted',
	'APV Placed',
	'Dials (300+)', // predefinedItems[6] uses in calcs
	'Hours Spent Dialing',
	'Apps Written', // predefinedItems[8] uses in calcs
	'No Shows',
	'FIF Resets Submitted',
	'ASR Booked',
	'Reschedules', // predefinedItems[12] uses in calcs
	'ASR Contacts', // predefinedItems[13] uses in calcs
]

const leftColConfig = [
	[0, 1, 'Lead Resolution (80%+)'],
	[2, 3, 'Sit Ratio'],
	[4, 5, 'Net Placed Ratio (75%)'],
	[6, 7, 'Dials Per Hour (30+)'],
]

const rightColConfig = [
	[8, 3, 'Apps Per Family Seen'],
	9,
	10,
	11,
	12,
	13,
	14,
	15,
]

const defaultActivities = new Array(16).fill(null).map((_, idx) => ({
	ActivityName: predefinedItems[idx] || null,
	ActivityAmount: null,
	inputKey: 0,
	saving: false,
	index: idx,
	predefined: !!predefinedItems[idx],
}))

export default {
    components: {
		DashCard,
		WeeklyActivityTrackerIcon,
	},
	props: {
		items: {
			type: Object,
			default: null,
		},
		agent: {
			type: String,
		},
		infoData: Object,
	},
	data () {
		return {
			predefinedItems,
			leftColConfig,
			rightColConfig,
			mData: defaultActivities.map(item => ({ ...item })),
		}
	},
    computed: {
		loading () {
			if (!this.items) { return false }
			return this.items.loading
		},
		hasError () {
			if (!this.items) { return false }
			return this.items.error
		},
		isDefaultData () {
			return this.mData.every(({ ID }) => !ID)
		},
		predefinedActivities () {
			const predefined = defaultActivities.filter(({ predefined }) => predefined)
			if (!this.items?.data) { return predefined }
			return predefined.map(item => {
				const entry = this.items.data.find(({ ActivityName }) => ActivityName === item.ActivityName)
				return entry
					? { ...entry, inputKey: 0, saving: false, index: item.index, predefined: true }
					: { ...item }
			})
		},
		customActivities () {
			const custom = defaultActivities.filter(({ predefined }) => !predefined)
			if (!this.items?.data) { return custom }
			const filteredData = this.items.data.filter(({ ActivityName }) => !this.predefinedActivities.some(item => item.ActivityName === ActivityName))
			return custom.map((item, idx) => {
				return filteredData[idx]
					? { ...filteredData[idx], inputKey: 0, saving: false, index: item.index, predefined: false }
					: { ...item }
			})
		},
		activitiesCols () {
			return [this.leftColConfig, this.rightColConfig]
		},
		updatedOn () {
			if (this.isDefaultData) { return undefined }
			const sortByDate = this.mData
				.filter(({ LastChangeDate }) => LastChangeDate)
				.sort((a, b) => moment(a.LastChangeDate, serverDateFormat).isAfter(moment(b.LastChangeDate, serverDateFormat)) ? -1 : 1)
			if (!sortByDate.length) { return undefined }
			return moment(sortByDate[0].LastChangeDate, serverDateFormat).format(dateFormat)
		},
		agentCode () {
			return this.agent ?? this.user.AgentCode
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
    },
	watch: {
		'items.data': {
			immediate: true,
			handler () {
				this.updateModelData()
			},
		},
	},
	methods: {
		save: debounce(
			async function (idx) {
				if (!this.isAllowEditing) { return }
				if (!this.validateItem(this.mData[idx])) { return }
				if (this.mData[idx].saving) { return }
				this.mData[idx].saving = true
				const { ID, ActivityName, ActivityAmount } = this.mData[idx]
				const value = this.checkNullish(ActivityAmount) ? null : Number(ActivityAmount)
				const res = ID
					? (await QuilityAPI.updateActivateActivityTracker(this.agentCode, ID, { ActivityName, ActivityAmount: value }))
					: (await QuilityAPI.createActivateActivityTracker(this.agentCode, { ActivityName, ActivityAmount: value }))
				if (res.error) {
					this.showError(`Oops! There was a problem submitting your request.<br>`, res.message)
					console.error(res.error)
				} else {
					Object.keys(res.data).forEach(key => {
						this.mData[idx][key] = res.data[key]
					})
					this.$emit('reload', 'activate_activity_tracker')
				}
				this.mData[idx].saving = false
			},
			2000
		),
		validateItem (item) {
			const { ID, ActivityName, ActivityAmount } = item
			if (!ActivityName) { return false }
			if (ID) {
				const activity = this.items.data.find(item => item.ID === ID)
				return (
					activity.ActivityName !== ActivityName ||
					activity.ActivityAmount !== ActivityAmount
				)
			}
			return true
		},
		updateModelData () {
			if (!this.items?.data) { return }
			const isNewAgent = this.items.data.every(({ ID }) => !this.mData.some(item => item.ID === ID))
			if (isNewAgent) {
				this.mData = [...this.predefinedActivities, ...this.customActivities]
			}
		},
		checkDisabledAmount ({ ActivityName, ActivityAmount }) {
			return (
				(this.isAllowEditing && !ActivityName) ||
				(!this.isAllowEditing && this.checkNullish(ActivityAmount))
			)
		},
		isNotManual (activityName) {
			return false
			// return (
			// 	activityName === predefinedItems[12] ||
			// 	activityName === predefinedItems[13]
			// )
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		onInput (value, idx, ix) {
			if (!this.isAllowEditing) { return }
			const caretPosition = this.getCaretPosition(idx, ix)
			const processed = this.processNumber(value)
			this.mData[idx].ActivityAmount = processed
			this.save(idx)
			this.mData[idx].inputKey += 1
			this.$nextTick(() => {
				const refItem = this.getRefItem(idx, ix)
				if (!refItem) { return }
				refItem.focus()
				this.setCaretPosition(caretPosition, idx, ix)
			})
		},
		processNumber (value) {
			const processed = [...String(value)]
				.filter(char => char !== ' ' && !Number.isNaN(Number(char)))
				.join('')
			return processed === '' ? undefined : Number(processed)
		},
		getCaretPosition (idx, ix) {
			const inputEl = this.getRefItem(idx, ix)?.$el.querySelector('input')
			return inputEl?.selectionStart
		},
		setCaretPosition (position, idx, ix) {
			const inputEl = this.getRefItem(idx, ix)?.$el.querySelector('input')
			return inputEl?.setSelectionRange(position, position)
		},
		getRefItem (idx, ix) {
			return this.$refs.inputField.find(ref => ref.$vnode.key.startsWith(`${idx}-${ix}-`))
		},
		calculateRatio (idx) {
			const [aIdx, bIdx] = idx
			const a = this.mData[aIdx]
			const b = this.mData[bIdx]
			if (a.ActivityAmount && b.ActivityAmount) {
				if (a.ActivityName === predefinedItems[6] || a.ActivityName === predefinedItems[8]) {
					const ratio = a.ActivityAmount / b.ActivityAmount
					return this.formatNumber(ratio, '(0[.]00)')
				} else {
					const ratio = b.ActivityAmount / a.ActivityAmount
					return this.formatNumber(ratio, '(0)%')
				}
			} else {
				return null
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	padding-top: 1rem;

	.sections-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.75rem;
		column-gap: 1.5rem;
		max-height: 30rem;
		overflow-y: auto;
	}

	.sections-col {
		max-width: 100%;
		flex: 1 1 16rem;
		display: flex;
		flex-direction: column;
		row-gap: 0.75rem;
	}

	.sections-block {
		border-radius: $field-border-radius;

		.sections-item__field {
			border-radius: 0;
		}
		.sections-item:first-child .sections-item__field {
			border-top-left-radius: $field-border-radius;
			border-top-right-radius: $field-border-radius;
		}
		.sections-item:last-child .sections-item__field {
			border-bottom-left-radius: $field-border-radius;
			border-bottom-right-radius: $field-border-radius;
		}
	}

	.sections-item {
		width: 100%;
		display: flex;
		height: 2.25rem;
		column-gap: 0.4rem;

		&__field {
			border-radius: $field-border-radius;
			background-color: $color-water-green-20;
			display: flex;
			align-items: center;
			padding: 0 0.625rem;

			::v-deep input {
				font-size: 0.875rem;
				font-weight: 600;
				padding-top: 2px !important;
			}

			::v-deep .v-input__append-inner {
				margin: 0 0 0 auto;
				align-self: center;

				.v-icon {
					color: #ffffff54;
					font-size: 16px !important;
				}
			}
		}

		.field-name {
			flex: 1 1 auto;
			position: relative;
		}

		.field-amount {
			flex: 0 0 3.75rem;
		}

		.field-loader {
			position: absolute;
			right: 0;
			top: 0;
		}

		.field-underlined {
			::v-deep .v-input__slot::before {
				border-color: transparent;
			}
		}

		.field-underlined-focused {
			::v-deep .v-input__slot::before {
				border-color: transparent !important;
				border-image: none;
			}

			::v-deep .v-input__slot::after {
				border-color: transparent;
			}
		}

		.bordered-field {
			position: relative;

			&::before {
				position: absolute;
				content: '';
				inset: 0 0.625rem;
				border-bottom: 1px solid $color-border;
				z-index: 0;
			}
		}

		.field-constant {
			font-size: 0.875rem;
			font-weight: 700;
			color: $color-water-green;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}
</style>
