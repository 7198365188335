<template>
	<DashCard
		title="Submit Product Mix"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		without-divider
		centered-title
		@reload="$emit('reload', 'activate_submit_product_mix')"
	>
		<template #title>
			<div class="dash-card__title-main">Submit Product Mix</div>
		</template>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getSubmitProductMixChartConfig, getSubmitProductMixChartOptions } from '../../Charts/chart-configs'

const emptyData = [
	{ ProductType_Monthly: 'Mortgage Protection', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
	{ ProductType_Monthly: 'Whole Life', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
	{ ProductType_Monthly: 'Permanent Life', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
	{ ProductType_Monthly: 'Retirement Solutions', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
	{ ProductType_Monthly: 'Term Life', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
	{ ProductType_Monthly: 'Quility Level Term', ProductAPV_Monthly: '', ProductAPV_Weekly: '' },
]

export default {
    components: {
		DashCard,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    data () {
        return {
			chartWidth: undefined,
		}
    },
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every(item => Object.values(item).every(this.checkNullish))
			}
			return false
		},
		computedData () {
			let data = emptyData
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			const monthly = data.map((item) => ({
				label: item.ProductType_Monthly || item.ProductType_Weekly,
				value: item.ProductAPV_Monthly,
			}))
			const weekly = data.map((item) => ({
				label: item.ProductType_Weekly || item.ProductType_Monthly,
				value: item.ProductAPV_Weekly,
			}))
			return this.monthlyPeriod ? monthly : weekly
		},
		chartOptions () {
			return getSubmitProductMixChartOptions(this.chartWidth, this.computedData?.length)
		},
		chartConfig () {
			const data = this.computedData.map(({ label, value }) => ([label, this.formatItem(value)]))
			return getSubmitProductMixChartConfig(data, this.chartOptions, this.formatMoney)
		},
    },
    methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatItem (value) {
			if (this.checkNullish(value)) { return null }
			return value
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(value, '0,0.00')
		},
		resizeChart (entries) {
			const { width } = entries[0].contentRect
			this.chartWidth = width
			this.chartKey += 1
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$chart-font-size-base: 1rem;
$chart-font-size-large: 1.5rem;
$chart-font-size-secondary: 0.8rem;

.#{$block} {
	color: $color-text;

	margin-top: 0.5rem;

	::v-deep .dash-chart {
		.highcharts-tooltip-box {
			stroke: transparent;
			fill: transparent;
			fill-opacity: 1;
		}

		.chart-tooltip-wrapper {
			padding: 8px;
			border-radius: 4px;
			background-color: var(--dash-bg);
		}

		.chart-tooltip-header {
			margin: 0;
			padding: 0.2em;
			font-size: $chart-font-size-secondary;
			border-bottom: 1px solid currentColor;
			font-weight: bold;
		}

		.chart-tooltip-point {
			margin: 0;
			padding: 0.2em;
			color: $color-text;
			font-size: $chart-font-size-large;
			font-weight: bold;
		}

		.highcharts-legend-item rect.highcharts-point {
			display: none !important;
		}

		.highcharts-legend-item {
			span {
				min-width: 6rem !important;
			}
		}

		.chart-legend-wrapper {
			width: 100%;
			padding-left: 0.4rem;
			border-left-width: 4px;
			border-left-style: solid;
			font-size: $chart-font-size-base;
		}

		.chart-legend-header {
			margin: 0;
			font-size: 0.75em;
			font-weight: bold;
		}

		.chart-legend-point {
			margin: 0;
			padding: 0;
			font-size: 1em;
			font-weight: normal !important;
		}
	}
}
</style>
