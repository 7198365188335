<template>
	<DashCard
		title="Distribution by LOL + Promotion"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: Distribution by LOL + Promotion"
		full-height
		@reload="$emit('reload', 'advance_devpipe')"
	>
		<div class="card-content with-scroll">
			<table class="advance-simple-table">
				<thead>
					<tr>
						<th />
						<th>Base</th>
						<th>Direct</th>
						<th>Total<br>Agency</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in computedData" :key="item.key">
						<td class="advance-simple-table__metric-col" v-html="item.label" />
						<td class="text-left">{{ item.Base }}</td>
						<td class="text-left">{{ item.Direct }}</td>
						<td class="text-left">{{ item.Total }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'

import { distributionConfig as config } from '../../config/developmentPipeline'

export default {
    components: {
		DashCard,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish) // TODO
			}
			return false
		},
		computedData () {
			let data = []
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			return this.getData(data)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getData (data) {
			return config.map((item) => {
				const metricData = data.find(({ Metric }) => Metric === item.key) ?? {}
				return {
					...item,
					Base: this.formatItem(metricData.BaseValue),
					Direct: this.formatItem(metricData.DirectValue),
					Total: this.formatItem(metricData.TotalValue),
				}
			})
		},
		formatItem (value) {
			const isNullish = this.checkNullish(value)
			if (isNullish) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.dash-card {
	padding: 0;
}

.card-content {
	overflow-x: auto;
}

.advance-simple-table {
	tr td {
		border-bottom: 1px solid $color-divider;
	}
}
</style>
