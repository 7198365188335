<template>
	<DashCard
		title="Positive Energy"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Positive Energy"
		style="min-height:100%;"
		@reload="$emit('reload', 'accelerate_positive_energy')"
	>
		<template #icon><Icon /></template>

		<template #noData>
			<div style="max-width:400px">
				Data will be displayed once the 4 survey minimum has been reached
			</div>
		</template>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import hcMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import solidGauge from 'highcharts/modules/solid-gauge'

import DashCard from '../DashCard.vue'
import Icon from '../../Icons/PositiveEnergyIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getPositiveEnergyChartConfig } from '../../Charts/chart-configs'

hcMore(Highcharts)
solidGauge(Highcharts)

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return this.data.data.positive_energy
			}
			return null
		},
		chartConfig () {
			return getPositiveEnergyChartConfig(this.computedData)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;
	width: 100%;
	margin-top: 1.5rem;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	.dash-chart {
		width: 100%;
		max-height: 12rem;
		max-width: 12rem;
	}
}
</style>
