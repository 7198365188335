import { throttle } from 'lodash'

const ChartMixin = {
	data () {
		return {
			observer: null,
			chartKey: 1,
		}
	},
	mounted () {
		this.initChartContainerObserver()
	},
	beforeDestroy () {
		this.destroyChartContainerObserver()
	},
	methods: {
		initChartContainerObserver () {
			if (this.$refs.chartContainer) {
				this.observer = new ResizeObserver(throttle(this.resizeChart, 50))
				this.observer.observe(this.$refs.chartContainer)
			}
		},
		destroyChartContainerObserver () {
			if (this.observer) {
				this.observer.disconnect?.()
				this.observer = null
			}
		},
		resizeChart () {
			this.chartKey += 1
		},
	}
}

export default ChartMixin
