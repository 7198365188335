<template>
	<DashCard
		title="Self Leadership"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		@reload="$emit('reload', 'activate_self_leadership')"
	>
		<template #icon><SelfLeadershipIcon /></template>

		<div ref="chartContainer">
			<div class="card-content" :class="isMobileView && 'card-content--mobile'">
				<div v-for="({ title, config }) in chartConfig" :key="title" class="chart-wrapper">
					<div class="chart-wrapper-content">
						<div class="chart__responsive">
							<highcharts :key="chartKey" :options="config" class="dash-chart" />
						</div>
						<div class="chart__title">{{ title }}</div>
					</div>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import hcMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import solidGauge from 'highcharts/modules/solid-gauge'

import DashCard from '../DashCard.vue'
import SelfLeadershipIcon from '../../Icons/SelfLeadershipIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getSelfLeadershipChartConfig } from '../../Charts/chart-configs'

hcMore(Highcharts)
solidGauge(Highcharts)

const config = [
	{ key: 'prioritization', label: 'Prioritization' },
	{ key: 'calenderManagement', label: 'Calendar Management' },
	{ key: 'focus', label: 'Focus' },
]

const getData = (data, period) => ({
	prioritization: data[`Prioritization_${period}`],
	calenderManagement: data[`CalendarManagement_${period}`],
	focus: data[`Focus_${period}`],
})

export default {
    components: {
		DashCard,
		SelfLeadershipIcon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			const data = this.data?.data || {}
			const monthly = getData(data, 'Monthly')
			const weekly = getData(data, 'Weekly')
			return this.monthlyPeriod ? monthly : weekly
		},
		chartConfig () {
			return config.map(({ key, label }) => ({
				title: label,
				config: getSelfLeadershipChartConfig(this.calculateRatio(this.computedData[key])),
			}))
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		calculateRatio (value) {
			if (this.checkNullish(value)) { return null }
			return Number(this.formatNumber(100 * value, '0'))
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$min-width: 8rem;
$max-width: 8rem;

.#{$block} {
	--font-size: 1rem;

	&--mobile {
		--font-size: 1rem;
	}

	color: $color-text;
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	gap: 2rem;
	justify-content: center;
	flex-wrap: wrap;

	.chart-wrapper {
		flex-grow: 1;
		min-width: $min-width;
		max-width: $max-width;
	}

	.chart-wrapper-content {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.chart__responsive {
		position: relative;
		width: 100%;
		padding-bottom: 50%;
	}

	.dash-chart {
		position: absolute;
		inset: 0;
	}

	.chart__title {
		margin-top: 0.5rem;
		width: $min-width;
		line-height: 1;
		font-size: var(--font-size);
		font-weight: bold;
		text-align: center;
	}
}
</style>
