import moment from 'moment'

import { agencyLevels } from './common'

export const metrics = {
	Production: 'Production',
	Recruiting: 'Recruiting',
	Onboarding: 'Onboarding',
	Activation: 'Activation',
	Development: 'Development',
}

export const chartsConfig = [
	{
		metric: metrics.Production,
		title: 'Production',
		chartLabel: 'Submit APV',
		metricLabel: 'SubmitAPV',
		dropdownLabel: '',
	},
	{
		metric: metrics.Recruiting,
		title: 'Recruiting',
		chartLabel: 'Invites Sent',
		metricLabel: 'InvitesSent',
		dropdownLabel: 'Recruiting Activity',
	},
	{
		metric: metrics.Onboarding,
		title: 'Onboarding',
		chartLabel: 'Ready to Writers',
		metricLabel: 'RTWs',
		dropdownLabel: 'Onboarding Activity',
	},
	{
		metric: metrics.Activation,
		title: 'Activation',
		chartLabel: 'New Writers',
		metricLabel: 'NWs',
		dropdownLabel: 'Activation Activity',
	},
	{
		metric: metrics.Development,
		title: 'Development',
		chartLabel: 'Consistent Writers',
		metricLabel: 'CWs',
		dropdownLabel: 'Development Activity',
	},
]

export const requestDefault = {
	AgentCode: "SFG0000001",
	AgentID: 458424,
	BusinessYear: "2023",
	BusinessMonth: "7",
	AgencyLevel: "Base",
	ContractLevel: "125",
}

export const GoalAPV = 'GoalAPV'

export const chartsLevels = [GoalAPV, ...Object.values(agencyLevels)]

export const chartsLevelLabels = {
	GoalAPV: 'Goal APV',
	[agencyLevels.Base]: 'Base',
	[agencyLevels.Direct]: 'Direct',
	[agencyLevels.Total]: 'Total Agency',
}

export const colors = {
	[GoalAPV]: '#FFB249',
	[agencyLevels.Base]: '#C0BBDB',
	[agencyLevels.Direct]: '#46C3B2',
	[agencyLevels.Total]: '#66B5F3',
}

const getDates = () => {
	let date = moment().subtract(1, 'months')
	return Array(6).fill(1).map(() => {
		const current = date.format('YYYY-MM')
		date = date.subtract(1, 'months')
		return current.split('-').map(Number)
	})
}

const currentDates = getDates()

export const getMetricData = (config, dates = currentDates) => {
	let data = Object.values(agencyLevels).map((level) => {
		return dates.map(([year, month]) => ({
			...requestDefault,
			AgencyLevel: level,
			BusinessYear: year,
			BusinessMonth: month,
			[config.metricLabel]: Math.round(Math.random() * 100),
		}))
	}).flat()
	if (config.metric === metrics.Production) {
		data.push(...dates.map(([year, month]) => ({
			...requestDefault,
			AgencyLevel: GoalAPV,
			BusinessYear: year,
			BusinessMonth: month,
			SubmitGoal: Math.round(Math.random() * 100),
		})))
	}
	return data
}

export const getDefaultTrendsData = () => {
	return chartsConfig.map((conf) => ({
		metric: conf.metric,
		data: getMetricData(conf, currentDates),
	}))
}

export const trendsDropdownConfigs = {
	[metrics.Recruiting]: [
		{ key: 'InvitesSent', label: 'Invites Sent' },
		{ key: 'InvitesAccepted', label: 'Invites Accepted' },
		{ key: 'InvitesAcceptanceRate', label: 'Invites Acceptance Rate', type: 'percent' },
	],
	[metrics.Onboarding]: [
		{ key: 'DaysToOnboard', label: 'Days to Onboard' },
		{ key: 'RTWs', label: `# RTW's` },
	],
	[metrics.Activation]: [
		{ key: 'ActivationRate', label: 'Activation Rate', type: 'percent' },
		{ key: 'DaysToApp_Referral', label: 'Days to First App (<i>Referral</i>)' },
		{ key: 'DaysToApp_Lead', label: 'Days to First App (<i>Lead</i>)' },
		{ key: 'DaysTo6Apps', label: 'Days to 6 Apps' },
		{ key: 'DaysTo15Apps', label: 'Days to 15 Apps' },
		{ key: 'WriterConversions', label: 'Writer Conversions', type: 'row' },
		{ key: 'NWsTo6Apps', label: 'Writer to 6 Apps' }, // TODO: type: 'percent'
		{ key: 'NWsTo15Apps', label: 'Writer to 15 Apps' }, // TODO: type: 'percent'
		{ key: 'NWsTo5k', label: 'Writer to <span class="with-currency">5K</span>' },
		{ key: 'NWsToCW', label: 'Writer to Consistent Writer' }, // TODO: type: 'percent'
		{ key: 'NWsActive', label: 'Percent of Writers Active', type: 'percent' },
	],
	[metrics.Development]: [
		{ key: 'APVPerUW', label: 'APV per Writer', type: 'currency' },
		{ key: 'AppsPerUW', label: 'Apps per Writer' },
		{ key: 'UWsActive', label: 'Percent of Active Writers', type: 'percent' },
		{ key: 'UWsToCW', label: 'Percent of Consistent Writers', type: 'percent' },
	],
}
