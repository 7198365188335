<template>
	<DashCard
		title="Energy Management"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Energy Management"
		style="min-height:100%;"
		@reload="$emit('reload', 'accelerate_energy_management')"
	>
		<template #icon><Icon /></template>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/LeadHealthIcon.vue'

import ChartMixin from '../../Charts/chart-mixin'
import { getEnergyManagementChartConfig, getEnergyManagementChartOptions } from '../../Charts/chart-configs'

const config = [
	{ key: 'physical', label: 'Physical' },
	{ key: 'emotional', label: 'Emotional' },
	{ key: 'mental', label: 'Mental' },
	{ key: 'spiritual', label: 'Spiritual' },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
	},
    data () {
        return {
			chartOptions: getEnergyManagementChartOptions(),
		}
    },
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				const { data } = this.data
				const monthly = config.map(item => ({ ...item, value: this.getValue(data[`${item.key}_monthly`]) }))
				const weekly = config.map(item => ({ ...item, value: this.getValue(data[`${item.key}_weekly`]) }))
				return this.monthlyPeriod ? monthly : weekly
			}
			return config.map(item => ({ ...item, value: null }))
		},
		total () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return (this.monthlyPeriod ? this.data.data.total_monthly : this.data.data.total_weekly) || ''
			}
			return ''
		},
		totalLabel () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return (this.monthlyPeriod ? this.data.data.total_label_monthly : this.data.data.total_label_weekly) || ''
			}
			return ''
		},
		chartConfig () {
			const data = this.computedData.map(({ label, value }) => ([label, value]))
			return getEnergyManagementChartConfig(data, this.total, this.totalLabel, this.chartOptions)
		},
    },
    methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getValue (value) {
			if (this.checkNullish(value)) { return null }
			return value
		},
		resizeChart (entries) {
			const { width } = entries[0].contentRect
			this.chartOptions = getEnergyManagementChartOptions(width)
			this.chartKey += 1
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$chart-font-size-base: 1rem;
$chart-font-size-large: 1.5rem;
$chart-font-size-secondary: 0.8rem;

.#{$block} {
	color: $color-text;

	width: 100%;
	margin-top: 1.5rem;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	::v-deep .dash-chart {
		.highcharts-tooltip-box {
			stroke: transparent;
			fill: transparent;
			fill-opacity: 1;
		}
	
		.chart-tooltip-wrapper {
			padding: 8px;
			border-radius: 4px;
			background-color: var(--dash-bg);
		}
	
		.chart-tooltip-header {
			margin: 0;
			padding: 0.2em;
			font-size: $chart-font-size-secondary;
			border-bottom: 1px solid currentColor;
			font-weight: bold;
		}
	
		.chart-tooltip-point {
			margin: 0;
			padding: 0.2em;
			color: $color-text;
			font-size: $chart-font-size-large;
			font-weight: bold;
		}
	
		.highcharts-legend-item rect.highcharts-point {
			display: none !important;
		}
	
		.highcharts-legend-item {
			span {
				width: 6rem !important;
			}
		}
	
		.chart-legend-wrapper {
			width: 100%;
			padding-left: 0.5rem;
			border-left-width: 4px;
			border-left-style: solid;
			font-size: $chart-font-size-base;
			font-weight: bold;
		}
	
		.chart-legend-header {
			margin: 0;
			font-size: 0.75em;
		}
	
		.chart-legend-point {
			margin: 0.2rem 0 0;
			padding: 0 0 0 0.2rem;
			font-size: 1em;
		}
	}
}
</style>
