<template>
	<DashCard
		title="Contract Levels"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: Contract Levels"
		full-height
		@reload="$emit('reload', 'advance_contract_levels')"
	>
		<template #title-right>
			<slot name="agency-level" />
		</template>

		<div class="card-content contract-levels-card__row">
			<div ref="chartContainer" class="contract-levels-card__chart-col">
				<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
			</div>

			<div class="contract-levels-card__levels-col">
				<div class="contract-level__title">Contract Level</div>
				<div class="contract-level__list">
					<div v-for="({ level, color }) in computedLevels" :key="level" class="contract-level__item">
						<div class="contract-level__item-mark" :style="`backgroundColor:${color}`" />
						<div class="contract-level__item-label">{{ level }}</div>
					</div>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'

import ChartMixin from '../../Charts/chart-mixin'
import { getContractLevelsChartConfig, getContractLevelsChartOptions } from '../../Charts/chart-configs'

import { levels, levelColor } from '../../config/developmentPipeline'

export default {
    components: {
		DashCard,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
	},
    data () {
        return {
			chartOptions: getContractLevelsChartOptions(),
			activeChartItem: null,
		}
    },
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data || !this.data.data.length) { return true }
				return this.data.data.every(({ AgentCount }) => this.checkNullish(AgentCount))
			}
			return false
		},
		computedData () {
			let data = []
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			return this.getData(data)
		},
		computedLevels () {
			return this.computedData.map(({ ContractLevel, color }) => ({ level: ContractLevel, color }))
		},
		total () {
			return this.computedData.reduce((acc, item) => {
				acc = acc + item.AgentCount
				return acc
			}, 0)
		},
		current () {
			if (!this.activeChartItem) { return null }
			return this.computedData.find(({ ContractLevel }) => ContractLevel === this.activeChartItem)?.AgentCount
		},
		chartConfig () {
			const data = this.computedData.map(({ ContractLevel, AgentCount, color }) => ({
				y: Number(this.formatNumber(100 * AgentCount / this.total, '0[.]0')),
				name: ContractLevel,
				color,
				events: {
					mouseOver: (evt) => this.mouseOver(evt),
					mouseOut: this.mouseOut,
				},
			}))
			const series = [{ data }]
			return getContractLevelsChartConfig(series, this.current, this.chartOptions)
		},
    },
    methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getData (data) {
			let items = data
			if (!data.length) {
				items = levels.map((level) => ({
					ContractLevel: level,
					AgentCount: Math.round(Math.random() * 100),
				}))
			}
			return items
				.map(({ ContractLevel, AgentCount }) => ({
					ContractLevel: Number(ContractLevel),
					AgentCount: Number(AgentCount),
					color: levelColor[ContractLevel],
				}))
				.filter(({ AgentCount }) => AgentCount)
		},
		resizeChart (entries) {
			const { width } = entries[0].contentRect
			this.chartOptions = getContractLevelsChartOptions(+Math.round(width))
			this.chartKey += 1
		},
		mouseOver (evt) {
			this.activeChartItem = evt?.target?.name
		},
		mouseOut () {
			this.activeChartItem = null
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.dash-card {
	padding: 0;
}

$block: card-content;
$chart-font-size-base: 1rem;
$chart-font-size-secondary: 0.875rem;

.#{$block} {
	color: $color-text;

	width: 100%;
	height: 15rem;
	padding-top: 0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&.contract-levels-card__row {
		min-height: 100%;
		column-gap: 1rem;
	}

	.contract-levels-card__chart-col {
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
	}

	.contract-levels-card__levels-col {
		flex: 0 0 116px;
		height: 100%;
	}

	.contract-level {
		&__title {
			height: 1.5rem;
			font-size: 0.875rem;
			line-height: 1;
			padding: 0.25rem;
			font-weight: 700;
			text-align: center;
			border-bottom: 1px solid $color-divider;
		}

		&__list {
			width: 100%;
			height: calc(100% - 1.5rem);
			padding: 0.75rem 0 0.5rem;
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			gap: 1rem 1.25rem;
			font-size: 0.875rem;
			line-height: 1;
		}

		&__item {
			width: 2.5rem;
			height: 1rem;
			display: flex;
			align-items: center;
			column-gap: 0.5rem;
		}

		&__item-mark {
			height: 100%;
			width: 5px;
		}
	}

	::v-deep .dash-chart {
		.highcharts-tooltip-box {
			stroke: transparent;
			fill: transparent;
			fill-opacity: 1;
		}

		.chart-tooltip-wrapper {
			padding: 2px;
			min-width: 3.5rem;
			border-radius: 2px;
			background-color: var(--dash-bg);
		}

		.chart-tooltip-header {
			margin: 0;
			padding: 0.2em;
			font-size: $chart-font-size-secondary;
			border-bottom: 1px solid currentColor;
			font-weight: bold;
			text-align: center;
		}

		.chart-tooltip-point {
			margin: 0;
			padding: 0.2em;
			color: $color-text;
			font-size: $chart-font-size-base;
			font-weight: bold;
			text-align: center;
		}
	}
}
</style>
