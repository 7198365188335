<template>
	<DashCard
		title="Switchboard Activity Report"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Activity Report"
		@reload="$emit('reload', 'activate_activity_report')"
	>
		<template #icon><ActivityReportIcon /></template>

		<div class="card-content" :class="isMobileView && 'card-content--mobile'">
			<div class="content-table__row">
				<div v-for="data in computedData" :key="data[0].key" class="content-table__col">
					<v-data-table
						:headers="headers"
						:items="data"
						item-key="key"
						mobile-breakpoint="0"
						class="card-table"
						dark hide-default-footer hide-default-header disable-sort
					>
						<template #item.metric="{ item }">
							<div><strong>{{ item.metric }}</strong></div>
						</template>
						<template #item.value="{ item }">
							<span class="nowrap" :class="item.comparable && 'font-weight-bold'">
								<CalculationDot
									:transparent="!item.comparable"
									:actual="item.value"
									:suggested="item.suggested"
									:thresholds="item.thresholds"
								/>
								<span>{{ formatItem(item.value) }}</span>
							</span>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import ActivityReportIcon from '../../Icons/ActivityReportIcon.vue'
import CalculationDot from '../../Components/CalculationDot.vue'

const config = [
	{ key: 'contacts', metric: 'Leads Uploaded', position: 1 },
	{ key: 'set', metric: 'Set', position: 2 },
	{ key: 'seen', metric: 'Seen', comparable: true, thresholds: { red: -0.5, yellow: -0.35 }, position: 3 },
]

const getData = (data, period) => ({
	contacts: data[`Contacts_${period}`],
	set: data[`Set_${period}`],
	seen: data[`Seen_${period}`],
	suggestedSeen: data[`Set_${period}`],
})

export default {
    components: {
		DashCard,
		ActivityReportIcon,
		CalculationDot,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		headers () {
			return [{
                text: 'Metric',
                value: 'metric',
            }, {
                text: 'Value',
                value: 'value',
			}]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			const data = this.data?.data || {}
			const periodData = this.monthlyPeriod
				? getData(data, 'Monthly')
				: getData(data, 'Weekly')
			const cols = this.isMobileView
				? [1]
				: [...new Set(config.map(item => item.position))].sort((a, b) => a - b)
			return cols.map(pos => config
				.filter(item => this.isMobileView ? true : item.position === pos)
				.map(({ position, ...item }) => ({
					...item,
					value: periodData[item.key],
					suggested: this.getSuggested(item.key, periodData),
				}))
			)
		},
    },
	methods: {
		getSuggested(key, data) {
			const Key = key[0].toUpperCase() + key.slice(1)
			return data[`suggested${Key}`]
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatItem (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	--td-padding: 0 0.5rem;

	&--mobile {
		--td-padding: 0 0.75rem;
	}

	.content-table {
		&__row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			row-gap: 0;
			column-gap: 0;
		}

		&__col {
			max-width: 100%;
			flex: 1 1 10rem;
		}
	}

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep tbody tr td {
			height: 42px;
			border-bottom: $border-bottom-td;
			padding: var(--td-padding);

			div {
				display: flex;
				align-items: center;
				color: $color-text;
				line-height: 1.2;
			}

			&:first-child {
				background-color: $color-water-green-20;
			}

			&:last-child {
				width: 5rem;
			}
		}
	}
}
</style>
