<template>
	<v-dialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="mobileFullscreen && $vuetify.breakpoint.xs"
		:content-class="computedContentClass"
	>
		<v-card class="info-card">
			<v-card-title class="info-card-title">
				<div class="flex-grow-1">
					<slot name="title">{{ title }}</slot>
				</div>
				<v-btn
					icon
					class="info-card-title-btn"
					@click="computedValue = false"
				>
					<v-icon>fa fa-times</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="info-card-text">
				<v-progress-linear v-if="loading" indeterminate />
				<slot v-else>
					<div v-html="content" />
				</slot>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Title' },
		content: { type: String, default: 'Content' },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '800px' },
		loading: { type: Boolean, default: false },
		mobileFullscreen: { type: Boolean, default: true },
		contentClass: { type: String },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedContentClass () {
			return [
				'perform-dash__info-dialog',
				this.isMobileView && 'perform-dash__info-dialog--mobile',
				this.contentClass,
			].filter(Boolean).join(' ')
		},
	},
}
</script>

<style lang="scss" scoped>
.perform-dash__info-dialog {
	.info-card {
		position: relative;
	}

	.info-card-title {
		display: flex;
		flex-wrap: nowrap;
		font-weight: bold !important;
		color: #000000 !important;
	}

	.info-card-title-btn {
		margin-left: 0.5rem;
		align-self: flex-start;
		flex-shrink: 0;
	}

	.info-card-text {
		color: #000000 !important;
	}
}
</style>
