<template>
	<div v-if="performDashSurveySnoozed" class="center-center">
		<v-progress-circular  size="30" indeterminate></v-progress-circular>
		<v-dialog
			:value="true"
			persistent
			width="100%"
			max-width="960px"
		>
			<MessageCardWithFml 
				:message="performDashSurveyPopup"
				hide-snooze
				@acknowledgeAndClose="acknowledge()"
			/>
		</v-dialog>
	</div>
	<div v-else>
		<slot />
	</div>
</template>

<script>
import MessageCardWithFml from "@/modules/messages/components/MessageCardWithFml.vue";
import { getSnoozedNotices, updateFlag } from "@/store/API/PopupAcknowledgements";
import PopupNotice from '@/store/Models/PopupNotice'

export default {
	components: {
		MessageCardWithFml,
	},
	data() {
		return {
			snoozedNoticesPromise: null,
			snoozedNotices: [],
		}
	},
	computed: {
		performDashSurveyPopup() {
			const {admin_notices} = this.$store.state.entities.popup_notice
			return admin_notices.find((item) => item.title.includes('PerformDash'))
		},
		performDashSurveySnoozed() {
			return !!this.performDashSurveyPopup
		},
		performDashSurveyNotice() {
			return this.snoozedNotices.find((item) => item.popup_id === this.performDashSurveyPopup?.id)
		},
	},
	methods: {
		async acknowledge() {
			if (this.snoozedNoticesPromise) {
				await this.snoozedNoticesPromise
			}

			if (!this.performDashSurveyNotice) {
				return
			}

			const response = await updateFlag(this.performDashSurveyNotice.id, {
				acknowledged: true,
			})

			if (!response.acknowledged) {
				console.error("Message set as acknowledged, but backend returned false, setting force to true in FE");
				return
			}

			PopupNotice.commit((state) => {
				state.admin_notices = state.admin_notices.filter(({ id }) => id !== this.performDashSurveyNotice.popup_id)
			})
		},
	},
	watch: {
		performDashSurveySnoozed: {
			immediate: true,
			handler (newValue) {
				if (newValue) {
					this.snoozedNoticesPromise = getSnoozedNotices().then(({ data }) => {
						this.snoozedNotices = data;
					});
				}
			},
		},
	},
}
</script>
