<template>
	<DashCard
		:title="title"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		:info-data-title="infoDataTitle"
		@reload="$emit('reload', `advance_trends/${metric}`)"
	>
		<div class="trend-chart-card__label">{{ chartLabel }}</div>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import { groupBy } from 'lodash'

import DashCard from '../DashCard.vue'
import Icon from '../../Icons/SelfLeadershipIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getTrendsChartConfig, labelsMoneyFormatter, axisLabelsMoneyFormatter } from '../../Charts/chart-configs'

import { metrics, chartsConfig, colors, GoalAPV, getMetricData } from '../../config/trends'

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: { type: Object, default: null },
		metric: { type: String, default: metrics.Production },
		agencyLevels: { type: Array, default: () => [] },
		infoData: Object,
		title: String,
		chartLabel: String,
	},
    data () {
        return {
			activeChartName: null,
		}
    },
    computed: {
		config () {
			return chartsConfig.find((conf) => conf.metric === this.metric)
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => this.checkNullish(item[this.config.metricLabel]))
			}
			return false
		},
		computedData () {
			let data = getMetricData(this.config)
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
				if (this.metric === metrics.Production) {
					const grouped = groupBy(data, 'BusinessMonth')
					const submitGoalData = Object.values(grouped).map((items) => ({ ...items[0], AgencyLevel: GoalAPV }))
					data = [...data, ...submitGoalData]
				}
			}
			const grouped = groupBy(data, 'AgencyLevel')
			const sorted = Object.entries(grouped)
				.filter(([key]) => {
					if (key === GoalAPV) { return true }
					return this.agencyLevels.includes(key)
				})
				.map(([key, values]) => ({
					AgencyLevel: key,
					data: values.sort((a, b) => {
						if (Number(a.BusinessYear) > Number(b.BusinessYear)) { return 1 }
						if (Number(a.BusinessYear) < Number(b.BusinessYear)) { return -1 }
						return Number(a.BusinessMonth) >= Number(b.BusinessMonth) ? 1 : -1
					}),
				}))
			return sorted.reduce((acc, item) => {
				acc[item.AgencyLevel] = item.data
				return acc
			}, {})
		},
		xAxisCategories () {
			return Object.values(this.computedData)[0]?.map(({ BusinessMonth }) => Number(BusinessMonth))
		},
		yAxisCategories () {
			const values = Object.values(this.computedData).flat().map((item) => item.AgencyLevel === GoalAPV ? item.SubmitGoal : item[this.config.metricLabel])
			const maxValue = Math.max(...values)
			if (this.metric === metrics.Production) {
				return [0, 0.25, 0.5, 0.75, 1].map((ratio) => Math.round(ratio * maxValue))
			}
			if (maxValue <= 3) {
				return [0, 1].map((ratio) => Math.round(ratio * maxValue))
			}
			if (maxValue <= 10) {
				return [0, 0.5, 1].map((ratio) => Math.round(ratio * maxValue))
			}
			return [0, 0.25, 0.5, 0.75, 1].map((ratio) => Math.round(ratio * maxValue))
		},
		yAxisLabelsFormatter () {
			return this.metric === metrics.Production ? axisLabelsMoneyFormatter(this.formatNumber) : undefined
		},
		chartConfig () {
			const series = Object.entries(this.computedData).map(([name, items]) => ({
				name,
				color: colors[name],
				dashStyle: name === GoalAPV ? 'LongDash' : 'Solid',
				dataLabels: {
					useHTML: this.metric === metrics.Production,
					enabled: name === this.activeChartName,
					verticalAlign: 'bottom',
					...(this.metric === metrics.Production && { formatter: labelsMoneyFormatter(this.formatNumber) }),
				},
				data: items.map((item) => name === GoalAPV ? item.SubmitGoal : item[this.config.metricLabel]),
				events: {
					mouseOver: () => this.mouseOver(name),
					mouseOut: this.mouseOut,
				},
				type: name === GoalAPV ? 'line' : 'spline',
			}))
			return getTrendsChartConfig(series, this.xAxisCategories, this.yAxisCategories, this.yAxisLabelsFormatter)
		},
		infoDataTitle () {
			return `Advance: R.O.A.D. Dashboard. ${this.title}`
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getValue (value) {
			if (this.checkNullish(value)) { return null }
			return value
		},
		mouseOver (name) {
			this.activeChartName = name
		},
		mouseOut () {
			this.activeChartName = null
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.dash-card {
	padding: 0;

	::v-deep .dash-card__header {
		min-height: 2rem;
	}
}

.card-content {
	color: $color-text;
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	.dash-chart {
		width: 100%;
	}
}

.trend-chart-card__label {
	margin-top: 0.25rem;
	font-size: 0.875rem;
	font-weight: 700;
}
</style>
