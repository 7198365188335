<template>
	<div class="AO-promotion-tracker-month-card">
		<div class="AO-promotion-tracker-month-card__title">
			<span>Month {{ order }}</span>
			<v-divider class="AO-promotion-tracker-month-card__divider" dark vertical />
			<span class="AO-promotion-tracker-month-card__month">{{ month }}</span>
		</div>
		<SlidingScale
			v-for="item in computedData" :key="item.key"
			:label="item.metric"
			:value="item.value"
			:scale="item.nextPromotion"
			:money="item.money"
			:withTicks="item.withTicks"
			:height="slidingScaleHeight"
			class="mt-4"
		/>
	</div>
</template>

<script>
import SlidingScale from './SlidingScale.vue'

const config = [
	{ key: 'unique_writers', metric: 'Unique Writers', nextPromotion: 6, withTicks: true },
	{ key: 'direct_writers', metric: 'Direct Unique Writers', nextPromotion: 4, withTicks: true },
	{ key: 'submit', metric: 'Submit', nextPromotion: 50000, money: true },
	{ key: 'net_placed', metric: 'Net Placed', nextPromotion: 30000, money: true },
]

export default {
	components: {
		SlidingScale,
	},
	props: {
		order: { type: Number, default: 1 },
		data: { type: Object, default: null },
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isCardMobileOrder () {
			return this.$vuetify.breakpoint.mdAndDown
		},
		computedData () {
			if (!this.data) { return {} }
			return config.map(item => {
				const metric = item.key !== 'net_placed' || this.order !== 3 ? item.metric : 'Projected Net Placed'
				return { ...item, metric, value: this.data[item.key] }
			})
		},
		month () {
			if (!this.data) { return '' }
			const month = this.data.month
			return `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`
		},
		slidingScaleHeight () {
			if (this.isMobileView) { return '12px' }
			if (this.isCardMobileOrder) { return '16px' }
			return undefined
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

$block: AO-promotion-tracker-month-card;

.#{$block} {
	--font-title: 1.5rem;

	&__title {
		margin-top: 1.5rem;
		display: flex;
		align-items: center;
		font-size: var(--font-title);
		line-height: 1;
	}

	&__divider {
		margin: 0 0.5rem;
		border-color: $color-divider;
	}

	&__month {
		font-size: 0.875rem;
		font-weight: bold;
	}
}
</style>
