<template>
	<DashCard
		title="Team Health"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Team Health"
		style="min-height:100%"
		@reload="$emit('reload', 'accelerate_team_health')"
	>
		<template #icon><Icon /></template>

		<template #noData>
			<div style="max-width:400px">
				Data will be displayed once the 4 survey minimum has been reached
			</div>
		</template>

		<div ref="chartContainer" class="card-content">
			<div class="charts-container">
				<div v-for="(chartConfig, idx) in chartConfigGrouped" :key="idx" class="charts-container">
					<div v-for="({ title, config }) in chartConfig" :key="title" class="chart-wrapper">
						<div class="chart-wrapper-content">
							<div class="chart__responsive">
								<highcharts :key="chartKey" :options="config" class="dash-chart" />
							</div>
							<div class="chart__title">{{ title }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import hcMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import solidGauge from 'highcharts/modules/solid-gauge'

import DashCard from '../DashCard.vue'
import Icon from '../../Icons/TeamHealthIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getTeamHealthChartConfig } from '../../Charts/chart-configs'

hcMore(Highcharts)
solidGauge(Highcharts)

const config = [
	{ key: 'communication', label: 'Communication' },
	{ key: 'engagement', label: 'Engagement' },
	{ key: 'psychological_safety', label: 'Psychological Safety' },
	{ key: 'values', label: 'Values' },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return config.map(item => ({ ...item, value: this.getValue(this.data.data[item.key]) }))
			}
			return config.map(item => ({ ...item, value: null }))			
		},
		chartConfig () {
			return this.computedData.map(({ label, value }) => ({
				title: label,
				config: getTeamHealthChartConfig(value),
			}))
		},
		chartConfigGrouped () {
			return [
				[this.chartConfig[0], this.chartConfig[1]],
				[this.chartConfig[2], this.chartConfig[3]],
			]
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getValue (value) {
			if (this.checkNullish(value)) { return null }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$min-width: 7rem;
$max-width: 12rem;
$font-size: 0.8rem;

.#{$block} {
	color: $color-text;
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
}

.#{$block}, .card-content-no-data {
	.charts-container {
		display: flex;
		column-gap: 1rem;
		row-gap: 1.5rem;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;
		flex-grow: 1;
	}

	.chart-wrapper {
		flex-grow: 1;
		min-width: $min-width;
		max-width: $max-width;
	}

	.chart-wrapper-content {
		flex-grow: 1;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.chart__responsive {
		position: relative;
		width: 100%;
		padding-bottom: 50%;
	}

	.dash-chart {
		position: absolute;
		inset: 0;
	}

	.chart__title {
		margin-top: 0.5rem;
		width: $min-width;
		line-height: 1.2;
		font-size: $font-size;
		font-weight: bold;
		text-align: center;
	}
}
</style>
