<template>
	<DashCard
		title="Note Taker"
		:loading="loading"
		silent-loading
		:has-error="hasError"
		:info-data="infoData"
		:updated-on="updatedOn"
		@reload="$emit('reload', 'activate_note_taker')"
	>
		<template #icon><NoteTakerIcon /></template>

		<div class="card-content">
			<div v-if="isAllowEditing" class="card-content__add-new">
				<v-btn
					class="card-content__add-new-btn"
					color="white" text small
					@click="createItem"
				>
					<v-icon>icon-math-plus-q</v-icon>
					Add a new note
				</v-btn>
			</div>

			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="ID"
				mobile-breakpoint="0"
				class="card-table"
				dark hide-default-footer hide-default-header disable-sort
			>
				<template #item.NoteTitle="{ item }">
					<div class="block-ellipsis">
						<span>{{ item.NoteTitle }}</span>
					</div>
				</template>
				<template #item.tableActions="{ item }">
					<div class="table-actions">
						<v-btn v-if="isAllowEditing" color="#ffffff54" small fab icon :disabled="loading || hasError" @click.stop="editItem(item)">
							<v-icon>icon-edit-q</v-icon>
						</v-btn>
						<v-btn color="#ffffff54" small fab icon :disabled="loading || hasError" @click.stop="showItem(item)">
							<v-icon>icon-eye-q</v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
		</div>

		<NoteTakerDialog
			v-if="doDialog && dialogMode"
			v-model="doDialog"
			:item="currentItem"
			:mode="dialogMode"
			@save="$emit('reload', 'activate_note_taker')"
		/>
	</DashCard>
</template>

<script>
import moment from 'moment'

import DashCard from '../DashCard.vue'
import NoteTakerDialog from '../../Components/NoteTakerDialog.vue'
import NoteTakerIcon from '../../Icons/NoteTakerIcon.vue'

const serverDateFormat = 'YYYY-MM-DD'
const dateFormat = 'MM/DD/YYYY'

export default {
    components: {
		DashCard,
		NoteTakerIcon,
		NoteTakerDialog,
	},
	props: {
		items: {
			type: Object,
			default: null,
		},
		agent: {
			type: String,
		},
		infoData: Object,
	},
	data () {
		return {
			doDialog: false,
			currentItem: null,
			dialogMode: undefined, // new || edit || view
		}
	},
    computed: {
		headers () {
			return [{
                text: 'Date',
                value: 'LastChangeDate',
            }, {
                text: 'Entry Title',
                value: 'NoteTitle',
			}, {
                text: '',
                value: 'tableActions',
            }]
		},
		loading () {
			if (!this.items) { return false }
			return this.items.loading
		},
		hasError () {
			if (!this.items) { return false }
			return this.items.error
		},
		computedData () {
			if (!this.items?.data) { return [] }
			return [...this.items.data].map(
				item => ({ ...item, LastChangeDate: moment(item.LastChangeDate, serverDateFormat).format(dateFormat) })
			)
		},
		updatedOn () {
			if (!this.computedData.length) { return undefined }
			return this.computedData[0].LastChangeDate
		},
		agentCode () {
			return this.agent ?? this.user.AgentCode
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
    },
	watch: {
		doDialog (v) {
			if (!v) {
				this.dialogMode = undefined
				this.currentItem = null
			}
		},
	},
	methods: {
		createItem () {
			if (!this.isAllowEditing) { return }
			this.dialogMode = 'new'
			this.currentItem = null
			this.doDialog = true
		},
		editItem (item) {
			if (!this.isAllowEditing) { return }
			this.currentItem = this.items?.data?.find(({ ID }) => ID === item.ID)
			this.dialogMode = 'edit'
			this.doDialog = true
		},
		showItem (item) {
			this.currentItem = this.items?.data?.find(({ ID }) => ID === item.ID)
			this.dialogMode = 'view'
			this.doDialog = true
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	--td-padding: 0 0.5rem;

	color: $color-text;

	&__add-new {
		padding: 0.25rem 0;
		text-align: right;
		border-bottom: $border-bottom-th;
	}

	&__add-new-btn {
		text-transform: none;
		padding: 0 0.5rem !important;
	}

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep .v-data-table__wrapper {
			max-height: 210px;
			overflow: auto;
			padding-right: 5px;

			&::-webkit-scrollbar {
				width: $scrollbar-width-chrome;
				height: $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 16px $color-dark;
				border-radius: 2 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-water-green;
				border-radius: 0.5 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-corner {
				background: transparent;
			}
		}

		.table-actions {
			white-space: nowrap;
			text-align: end;
		}

		&::v-deep tbody tr:hover {
			background: none !important;
		}

		&::v-deep tbody tr td {
			height: 42px;
			border-bottom: $border-bottom-td;
			padding: var(--td-padding);
			line-height: 1;

			&:first-child {
				background-color: $color-water-green-20;
				text-align: center !important;
				width: 6rem;
				padding: 0 0.25rem;
			}

			&:last-child {
				width: 3rem;
				padding: 0;
			}
		}
	}

	.block-ellipsis {
		display: block;
		display: -webkit-box;
		max-width: 100%;
		max-height: 42px;
		font-size: 14px;
		line-height: 1.3;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
