<template>
	<InfoDialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:mobile-fullscreen="false"
		content-class="perform-dash__note-taker-dialog"
	>
		<template #title>
			<div class="dialog-title-wrapper">
				<h3 class="dialog-title">{{ title }}</h3>
				<p v-if="computedDate" class="dialog-title-date">{{ computedDate }}</p>
			</div>
		</template>

		<template v-if="mode === 'view'">
			<h5 class="note-title">{{ computedTitle }}</h5>
			<div>
				<p v-for="content in computedContent" :key="content" class="note-content">{{ content }}</p>
			</div>
		</template>

		<template v-else>
			<v-form ref="form" v-model="validForm" :disabled="saving" class="note-taker-form">
				<v-row dense>
					<v-col cols="12">
						<label for="field-NoteTitle" class="note-label">Note Entry Title</label>
						<v-text-field
							id="field-NoteTitle"
							v-model.trim="form.NoteTitle"
							:rules="[form_rules.required]"
							required outlined dense color="#46C3B2"
							placeholder="Start typing here"
							class="mt-1 pt-0"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<label for="field-NoteContent" class="note-label">Note Entry</label>
						<v-textarea
							id="field-NoteContent"
							v-model.trim="form.NoteContent"
							:rules="[form_rules.required]"
							required outlined :rows="6" color="#46C3B2"
							placeholder="Start typing here"
							class="mt-1 pt-0 with-scroll"
						/>
					</v-col>
				</v-row>

				<v-row dense>
					<v-alert v-if="warningMessage" dense type="warning" class="mb-8 full-width">{{ warningMessage }}</v-alert>

					<v-col class="d-flex">
						<v-btn v-if="deleteEnabled" class="perform-dash-btn perform-dash-btn__delete-btn" color="#FF5851" text @click="showConfirmationDialog = true">
							<v-icon color="#FF5851">icon-trash-q</v-icon>
							<span v-if="!isMobileView">Delete</span>
						</v-btn>
						<v-btn class="perform-dash-btn ml-auto" color="#000F2C" outlined @click="computedValue = false">
							Cancel
						</v-btn>
						<v-btn class="perform-dash-btn ml-2" color="#46C3B2" :disabled="!submitEnabled" :dark="submitEnabled" @click="save">
							<v-progress-circular v-if="saving" class="mr-2" left size="20" width="2" indeterminate />
							Save
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</template>

		<ConfirmationDialog
			v-model="showConfirmationDialog"
			:title="confirmationDialogTitle"
			:content="confirmationDialogContent"
			@confirm="deleteItem"
		/>
	</InfoDialog>
</template>

<script>
import moment from 'moment'

import QuilityAPI from '@/store/API/QuilityAPI'
import InfoDialog from './InfoDialog.vue'
import ConfirmationDialog from './ConfirmationDialog.vue'

const serverDateFormat = 'YYYY-MM-DD'
const dateFormat = 'MMMM DD, YYYY'

const dialogTitle = {
	new: 'New Note',
	edit: 'Edit Note',
	view: 'View Note',
}

const emptyForm = {
	NoteTitle: '',
	NoteContent: '',
}

export default {
	components: {
		InfoDialog,
		ConfirmationDialog,
	},
	props: {
		value: { type: Boolean, default: false },
		item: { type: Object },
		mode: { type: String, required: true },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '640px' },
	},
	data () {
		return {
			form: {},
			validForm: false,
			saving: false,
			warningMessage: '',
			showConfirmationDialog: false,
			confirmationDialogTitle: 'Are you sure you want to delete this entry?',
			confirmationDialogContent: 'This will delete this entry permanently. You cannot undo this action.',
		}
	},
	created () {
		this.form = this.mode === 'edit'
			? { ...this.item }
			: { ...emptyForm }
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		agentCode () {
			return this.user.AgentCode
		},
		title () {
			return dialogTitle[this.mode]
		},
		computedDate () {
			if (!this.item?.LastChangeDate) { return undefined }
			return moment(this.item.LastChangeDate, serverDateFormat).format(dateFormat)
		},
		computedTitle () {
			if (!this.item?.NoteTitle) { return '' }
			return this.item.NoteTitle
		},
		computedContent () {
			if (!this.item?.NoteContent) { return [] }
			return this.item.NoteContent.split('\n')
		},
		formChanges () {
			const base = this.mode === 'edit' ? (this.item || {}) : emptyForm
			return Object.keys(emptyForm)
				.map(key => {
					const before = base[key]
					const after = this.form[key]
					if (before === after) { return false }
					return { key, before, after }
				})
				.filter(Boolean)
		},
		deleteEnabled () {
			return this.mode === 'edit'
		},
		submitEnabled () {
			return Boolean(this.formChanges.length && !this.saving)
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
	},
	methods: {
		validateForm () {
			if (!this.$refs['form'].validate()) {
                this.warningMessage = 'Please correct invalid fields.'
                setTimeout(() => { this.warningMessage = '' }, 5000)
                return false
            }
			return true
		},
		async save () {
			if (!this.validateForm()) { return }
			if (this.saving) { return }
            this.saving = true
            const res = this.mode === 'new'
				? (await QuilityAPI.createActivateNote(this.agentCode, this.form))
				: (await QuilityAPI.updateActivateNote(this.form))
            if (res.error) {
                this.showError(`Oops! There was a problem submitting Note.<br>`, res.message)
                console.error(res.error)
            } else {
                this.$emit('save')
                this.computedValue = false
            }
            this.saving = false
		},
		async deleteItem () {
			if (!this.deleteEnabled) { return }
			if (!this.form.ID) { return }
			if (this.saving) { return }
            this.saving = true
            const res = await QuilityAPI.deleteActivateNote(this.form.ID)
            if (res.error) {
                this.showError(`Oops! There was a problem deleting Note.<br>`, res.message)
                console.error(res.error)
            } else {
                this.$emit('save')
                this.computedValue = false
            }
            this.saving = false
		},
	},
}
</script>

<style lang="scss">
@import '../style/index.scss';

.perform-dash__note-taker-dialog {
	--padding-x: 3rem;

	&:not(.v-dialog--fullscreen) {
		border-radius: $card-border-radius;

		.info-card {
			border-radius: $card-border-radius;
		}
	}

	.v-card__title {
		padding: 2.5rem var(--padding-x) 0 !important;
	}

	.v-card__text {
		padding: 1.5rem var(--padding-x) 2rem !important;
	}

	.info-card-title-btn {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.perform-dash__note-taker-dialog.perform-dash__info-dialog--mobile {
	--padding-x: 1.5rem;
}
</style>


<style lang="scss" scoped>
@import '../style/index.scss';

.perform-dash__note-taker-dialog {
	.dialog-title-wrapper {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 0.25rem 2rem;
		padding-bottom: 0.5rem;
		border-bottom: 2px solid $color-border-dark;
	}

	.dialog-title {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 1.125rem;
		color: $color-water-green;
		letter-spacing: normal;
	}

	.dialog-title-date {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 0.875rem;
		color: $color-dark;
	}

	.note-title {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 1rem;
		color: $color-dark;
	}

	.note-content {
		line-height: 1.5;
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-size: 0.875rem;
		color: $color-dark;
	}

	.note-label {
		line-height: 1.2;
		font-size: 0.875rem;
		font-weight: 600;
		color: $color-dark;
	}
}
</style>
