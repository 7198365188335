<template>
	<div class="sliding-scale">
		<div class="sliding-scale__header">
			<span class="sliding-scale__label">{{ $props.label }}</span>
			<span class="text-right">
				<span v-if="$props.withTicks">{{ ticksValue }}</span>
				<span v-else-if="$props.money">
					<span v-if="computedValue < 0">-</span>
					<span class="with-currency">{{ formattedValue }}</span>
				</span>
				<span v-else>{{ computedValue }}</span>
				/&nbsp;<span v-if="$props.withTicks">{{ ticksScale }}</span>
				<span v-else-if="$props.money" class="with-currency">{{ formattedScale }}</span>
				<span v-else>{{ computedScale }}</span>
			</span>
		</div>
		<div class="sliding-scale__scale" :style="`height: ${$props.height}`">
			<template v-if="$props.withTicks">
				<template v-if="isValidScale && isValidValue">
					<div
						v-for="tick in ticksScale" :key="tick"
						class="sliding-scale__scale-tick"
						:class="tick <= ticksValue && 'sliding-scale__scale-tick_filled'"
					/>
				</template>
				<div v-else class="sliding-scale__scale-track" />
			</template>
			<template v-else>
				<div class="sliding-scale__scale-value" :style="`width: ${computedWidth}%`" />
				<div v-if="computedWidth < 100" class="sliding-scale__scale-track" />
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: [String, Number],
			default: 0,
		},
		scale: {
			type: [String, Number],
			default: 1,
		},
		label: {
			type: String,
			default: '',
		},
		height: {
			type: String,
			default: '1.5rem',
		},
		money: {
			type: Boolean,
			default: false,
		},
		withTicks: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isValidScale () {
			const isNullish = [undefined, null, ''].includes(this.scale)
			const value = Number(this.scale)
			return !isNullish && !isNaN(value)
		},
		isValidValue () {
			const isNullish = [undefined, null, ''].includes(this.value)
			const value = Number(this.value)
			return !isNullish && !isNaN(value)
		},
		computedScale () {
			return this.isValidScale ? Number(this.scale) : 'NA'
		},
		computedValue () {
			return this.isValidValue ? Number(this.value) : 0
		},
		ticksScale () {
			if (!this.isValidScale) { return this.computedScale }
			return Math.floor(this.computedScale)
		},
		ticksValue () {
			if (!this.isValidValue) { return this.computedValue }
			return Math.floor(this.computedValue)
		},
		computedWidth () {
			if (!this.isValidScale || !this.isValidValue || this.computedValue < 0) { return 0 }
			const width = (this.computedValue / this.computedScale) * 100
			return Math.min(width, 100)
		},
		formattedScale () {
			if (this.money && this.isValidScale) {
				return this.formatNumber(this.computedScale, '(0,0)')
			}
			return this.computedScale
		},
		formattedValue () {
			if (this.money && this.isValidValue) {
				return this.formatNumber(Math.abs(this.computedValue), '(0,0)')
			}
			return this.computedValue
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

$block: sliding-scale;

.#{$block} {
	color: $color-text;

	&__header {
		width: 100%;
		margin-bottom: 6px;
		display: flex;
		justify-content: space-between;
		font-size: 0.875rem;
		line-height: 1rem;
	}

	&__label {
		margin-right: 1rem;
		font-weight: bold;
	}

	&__scale {
		width: 100%;
		display: flex;
	}

	&__scale-value {
		flex-shrink: 0;
		background-color: $color-water-green;
	}

	&__scale-track {
		flex-grow: 1;
		background-color: currentColor;
	}

	&__scale-tick {
		flex-grow: 1;
		background-color: currentColor;
		border-left: thin solid var(--card-bg);
		border-right: thin solid var(--card-bg);

		&_filled {
			background-color: $color-water-green;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}
	}
}
</style>
