<template>
	<DashCard
		title="Actual vs. Needed Submit"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		without-divider
		centered-title
		@reload="$emit('reload', 'actual_needed_submit')"
	>
		<template #title>
			<div class="dash-card__title-main">Actual vs. Needed Submit</div>
		</template>

		<div ref="chartContainer" class="card-content">
			<div class="charts__responsive">
				<GratitudesJournalCelebrations v-if="doShowCelebrations" />
				<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
			</div>
		</div>
	</DashCard>
</template>

<script>
import hcMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'

import GratitudesJournalCelebrations from '@/views/Gratitudes/GratitudesJournalCelebrations.vue'
import DashCard from '../DashCard.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getActualVsNeededSubmitChartConfig, getActualVsNeededSubmitChartOptions } from '../../Charts/chart-configs'

hcMore(Highcharts)

export default {
    components: {
		DashCard,
		GratitudesJournalCelebrations,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    data () {
        return {
			chartOptions: getActualVsNeededSubmitChartOptions(),
			doShowCelebrations: false,
			celebrationsShowTimer: null,
			celebrationsDelayTimer: null,
		}
    },
	beforeDestroy () {
		clearTimeout(this.celebrationsShowTimer)
		clearTimeout(this.celebrationsDelayTimer)
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data || !this.data.suggested) { return true }
				return [this.data.data.apv_monthly, this.data.data.apv_weekly, this.data.suggested.neededSubmit].every(this.checkNullish)
			}
			return false
		},
		computedData () {
			const monthly = {
				actual: this.data?.data?.apv_monthly ?? 0,
				needed: this.data?.suggested?.neededSubmit ?? 0,
			}
			const weekly = {
				actual: this.data?.data?.apv_weekly ?? 0,
				needed: this.data?.suggested?.neededSubmit ?? 0,
			}
			return this.monthlyPeriod ? monthly : weekly
		},
		chartConfig () {
			return getActualVsNeededSubmitChartConfig(
				this.computedData?.actual, this.computedData?.needed, this.chartOptions, this.formatNumber
			)
		},
    },
	watch: {
		computedData: {
			deep: true,
			immediate: true,
			handler: 'checkCelebrations',
		},
	},
    methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		resizeChart (entries) {
			const { width } = entries[0].contentRect
			this.chartOptions = getActualVsNeededSubmitChartOptions(width)
			this.chartKey += 1
		},
		showCelebrations () {
			this.doShowCelebrations = true
			this.celebrationsShowTimer = setTimeout(() => {
				this.doShowCelebrations = false
			}, 2500)
		},
		hideCelebrations () {
			this.doShowCelebrations = false
			clearTimeout(this.celebrationsShowTimer)
		},
		checkCelebrations () {
			if (this.celebrationsDelayTimer) { clearTimeout(this.celebrationsDelayTimer) }
			if (!this.computedData) { return }
			if (this.computedData.actual && this.computedData.actual >= this.computedData.needed) {
				this.celebrationsDelayTimer = setTimeout(() => { this.showCelebrations() }, 1500)
			} else {
				this.hideCelebrations()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	.charts__responsive {
		position: relative;
		width: 100%;
		padding-bottom: 50%;
	}

	.dash-chart {
		position: absolute;
		inset: 0;
	}
}
</style>
