<template>
	<v-select
		v-model="computedValue"
		:items="options"
		:disabled="disabled"
		hide-details
		dark
		:menu-props="{
			bottom: true,
			offsetY: true,
			contentClass: 'agency-level-selector__menu-content'
		}"
		class="agency-level-selector"
	/>
</template>

<script>
import { agencyLevels, agencyLevelsConfig } from '../config/common'

export default {
	props: {
		value: { type: String, default: agencyLevels.Base },
		options: { type: Array, default: () => agencyLevelsConfig },
		disabled: { type: Boolean, default: false },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

.agency-level-selector {
	margin-top: 0;
	padding-top: 0;
	max-width: 8.5rem;
	font-size: 0.875rem;
}
</style>

<style lang="scss">
@import '../style/index.scss';

.agency-level-selector__menu-content {
	.primary--text {
		color: $color-water-green !important;
	}

	border-radius: 2px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	box-shadow: none;
	border: 1px solid $color-card-dark-2;
	font-size: 0.875rem;

	.v-list {
		border-radius: inherit;
		padding: 0;
		background-color: $color-card-dark;
		color: $color-text;
	}

	.v-list-item {
		min-height: unset;
		padding: 0.5rem;

		&:not(.v-list-item--active):not(.v-list-item--disabled) {
			color: inherit !important;
		}

		&.v-list-item--disabled {
			color: $color-divider;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $color-divider;
		}
	}

	.v-list-item__content {
		padding: 0;
	}

	.v-list-item__title {
		font-size: inherit;
		font-weight: 600;
	}
}
</style>

