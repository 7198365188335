<template>
	<DashCard
		title="Leads Under Management"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: Leads Under Management"
		full-height
		@reload="$emit('reload', 'advance_leads_under_management')"
	>
		<template #icon><Icon /></template>

		<div class="card-content with-scroll">
			<table class="advance-simple-table">
				<thead>
					<tr>
						<th />
						<th>Base</th>
						<th>Direct</th>
						<th>Total<br>Agency</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in computedData" :key="item.key">
						<td class="advance-simple-table__metric-col" v-html="item.label" />
						<td class="text-left">{{ item.Base }}</td>
						<td class="text-left">{{ item.Direct }}</td>
						<td class="text-left">{{ item.Total }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/LeadsUnderManagementIcon.vue'

import { config } from '../../config/leadsUnderManagement'

export default {
    components: {
		DashCard,
		Icon,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => {
					const { NumBaseLeads, NumDirectLeads, NumTotalAgencyLeads } = item
					return this.checkNullish(NumBaseLeads) && this.checkNullish(NumDirectLeads) && this.checkNullish(NumTotalAgencyLeads)
				})
			}
			return false
		},
		computedData () {
			let data = []
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			return this.getData(data)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getData (data) {
			return config.map((item) => {
				const itemData = data.find(({ LeadType }) => LeadType === item.key) || {}
				return {
					...item,
					Base: this.formatItem(itemData.NumBaseLeads),
					Direct: this.formatItem(itemData.NumDirectLeads),
					Total: this.formatItem(itemData.NumTotalAgencyLeads),
				}
			})
		},
		formatItem (value) {
			const isNullish = this.checkNullish(value)
			if (isNullish) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.card-content {
	overflow-x: auto;
}

.advance-simple-table {
	th, td {
		padding: 0.5rem 0.5rem;
	}

	tr td {
		height: 3.25rem;
		border-bottom: 1px solid $color-divider;
	}
}
</style>
