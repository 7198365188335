<template>
	<DashCard
		title="AO Promotion Tracker"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: AO Promotion Tracker"
		:centered-title="!cardMobileOrder"
		@reload="$emit('reload', 'accelerate_ao_tracker')"
	>
		<template #icon><Icon /></template>

		<template #title>
			<div class="dash-card__title">
				<p class="mb-0">AO Promotion Tracker</p>
				<p class="dash-card__subtitle">Reminder: Track Progress to 105</p>
			</div>
		</template>

		<v-row>
			<v-col cols="12" lg="4" v-for="(card, idx) in computedData" :key="idx">
				<OAPromotionTrackerMonthCard
					:style="!cardMobileOrder && 'margin:0 10%'"
					:order="idx + 1"
					:data="card"
				/>
			</v-col>
		</v-row>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import OAPromotionTrackerMonthCard from '../../Components/OAPromotionTrackerMonthCard.vue'
import Icon from '../../Icons/ProgressTrackerIcon.vue'

const getData = () => ({
	unique_writers: '',
	direct_writers: '',
	submit: '',
	net_placed: '',
	month: '',
})

export default {
    components: {
		DashCard,
		Icon,
		OAPromotionTrackerMonthCard,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every(item => Object.values(item).every(this.checkNullish))
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return this.data.data
			}
			return [1, 2, 3].map(getData)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
	},
}
</script>

<style lang="scss" scoped>
.dash-card__subtitle {
	margin-bottom: 0;
	font-weight: normal;
	font-size: 0.9em;
}
</style>
