<template>
	<v-dialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="$vuetify.breakpoint.xs"
		content-class="perform-dash__new-dashboard-dialog"
	>
		<v-card class="dialog-card">
			<v-card-title class="d-flex flex-column justify-center">
				<NewDashboardUnlockedIcon />
				<div class="dialog-card__title">{{ title }}</div>
			</v-card-title>
			<v-card-text class="pb-8">
				<v-progress-linear v-if="loading" indeterminate />
				<slot v-else name="content">
					<div class="dialog-card__content" v-html="content" />
				</slot>
			</v-card-text>
			<v-card-actions class="justify-center">
				<v-btn
					class="dialog-card__btn"
					color="#0083eb"
					dark depressed
					@click="computedValue = false"
				>
					Next
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import NewDashboardUnlockedIcon from '../Icons/NewDashboardUnlockedIcon.vue'

export default {
	components: { NewDashboardUnlockedIcon },
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'New Dashboard Unlocked!' },
		content: { type: String, default: 'Content' },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '800px' },
		loading: { type: Boolean, default: false },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

.perform-dash__new-dashboard-dialog {
	&:not(.v-dialog--fullscreen) {
		.dialog-card {
			border-radius: $card-border-radius;
			padding: 2rem 1.5rem 2.5rem;
		}
	}

	.dialog-card__title {
		text-align: center;
		margin: 1.5rem 0;
		font-size: 2rem;
		color: $color-water-green;
		font-weight: bolder;
	}

	.dialog-card__content {
		font-size: 1.25rem;
		line-height: 1.875rem;
	}

	.dialog-card__btn {
		height: 3.0rem !important;
		width: 17.0rem !important;
		font-size: 1.25rem;
		font-weight: 600;
		text-transform: none;
	}
}
</style>
