export const distributionConfig = [
	{ key: 'RTWs', label: 'RTWs' },
	{ key: 'CWs', label: 'Consistent Writers' },
	{ key: '5kUWs', label: '<span class="with-currency">5K</span> Wk Writers' },
	{ key: 'TLs', label: 'TLs' },
	{ key: 'KLs', label: 'KLs' },
	{ key: 'Promotions', label: 'Promotions' },
]

export const levels = [80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130]

export const levelColor = {
	80: '#46C3B2',
	85: '#8176B8',
	90: '#FFB249',
	95: '#FFFFFF',
	100: '#99CDF7',
	105: '#FFDEDC',
	110: '#6ECF93',
	115: '#E57570',
	120: '#339BEF',
	125: '#FFEB99',
	130: '#955989',
}
