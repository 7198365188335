<template>
	<DashCard
		title="Quility Lead Marketplace"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		@reload="$emit('reload', 'activate_qlm')"
	>
		<template #icon><LeadMarketplaceIcon /></template>

		<div class="card-content">
			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="index"
				mobile-breakpoint="0"
				class="card-table"
				dark hide-default-footer disable-sort disable-pagination disable-filtering
			>
				<template #item="{ item }">
					<tr v-if="item.LeadSource && !item.LeadSubSource">
						<td colspan="5" class="source-title">{{ item.LeadSourceLabel }}</td>
					</tr>

					<tr v-else-if="item.LeadSubSource === 'Total'" class="summary-row" :class="item.isEmpty && 'row--empty'">
						<template v-if="item.isEmpty">
							<td></td>
							<td>&mdash;</td>
							<td>&mdash;</td>
							<td>&mdash;</td>
							<td>&mdash;</td>
						</template>
						<template v-else>
							<td></td>
							<td>{{ formatItem(item.LeadPurchase) }}</td>
							<td>
								<span class="nowrap">
									<span v-if="item.LeadCost < 0">-</span>
									<span class="with-currency">{{ formatMoney(item.LeadCost) }}</span>
								</span>
							</td>
							<td>{{ formatPercent(item.CloseRatio) }}%</td>
							<td>{{ formatPercent(item.TPCloseRatio) }}%</td>
						</template>
					</tr>

					<tr v-else :class="item.isEmpty && 'row--empty'">
						<td class="sub-source-title">{{ item.LeadSubSourceLabel }}</td>
						<template v-if="item.isEmpty">
							<td>&mdash;</td>
							<td>&mdash;</td>
							<td>&mdash;</td>
							<td>&mdash;</td>
						</template>
						<template v-else>
							<td>{{ formatItem(item.LeadPurchase) }}</td>
							<td>
								<span class="nowrap">
									<span v-if="item.LeadCost < 0">-</span>
									<span class="with-currency">{{ formatMoney(item.LeadCost) }}</span>
								</span>
							</td>
							<td>{{ formatPercent(item.CloseRatio) }}%</td>
							<td>{{ formatPercent(item.TPCloseRatio) }}%</td>
						</template>
					</tr>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import LeadMarketplaceIcon from '../../Icons/LeadMarketplaceIcon.vue'
import CalculationDot from '../../Components/CalculationDot.vue'

const checkNullish = (value) => {
	return [undefined, null, ''].includes(value)
}

const defaultDataConfig = [
	[
		{ key: 'Mail In', label: 'Mail-In' },
		['MP A', 'MP B-C', 'MP D-E', 'MP F-G', 'Total']
	],
	[
		{ key: 'Call In', label: 'Call-In' },
		['MP A', 'MP B-C', 'MP D-E', 'MP F-G', 'Total']
	],
	[
		{ key: 'Lighthouse', label: 'Digital Lighthouse' },
		['MP A', 'Life A', 'IUL A', { key: 'Retirement Solutions A', label: 'Retirement Solutions\u00A0A' }, { key: 'Infinite Banking A', label: 'Infinite Banking\u00A0A' }, 'DFL A', 'Total']
	],
	[
		{ key: 'Economy', label: 'Digital Economy' },
		['MP A', 'Life A', 'Total']
	],
]

const getEmptySubSource = (LeadSource, LeadSourceLabel, LeadSubSource, LeadSubSourceLabel) => ({
	LeadSource,
	LeadSourceLabel,
	LeadSubSource,
	LeadSubSourceLabel,
	LeadPurchase: null,
	LeadCost: null,
	CloseRatio: null,
	TPCloseRatio: null,
	isEmpty: true,
})

const getDefaultData = () => {
	const data = []
	defaultDataConfig.forEach(([LeadSource, LeadSubSources]) => {
		const LeadSourceKey = typeof LeadSource === 'string' ? LeadSource : LeadSource.key
		const LeadSourceLabel = typeof LeadSource === 'string' ? LeadSource : LeadSource.label
		const subSources = LeadSubSources.map(LeadSubSource => {
			const LeadSubSourceKey = typeof LeadSubSource === 'string' ? LeadSubSource : LeadSubSource.key
			const LeadSubSourceLabel = typeof LeadSubSource === 'string' ? LeadSubSource : LeadSubSource.label
			return getEmptySubSource(LeadSourceKey, LeadSourceLabel, LeadSubSourceKey, LeadSubSourceLabel)
		})
		data.push({ LeadSource: LeadSourceKey, LeadSourceLabel }, ...subSources)
	})
	return data
}

const getQLM = (period, data) => {
	const qlm = []
	defaultDataConfig.forEach(([LeadSource, LeadSubSources]) => {
		const LeadSourceKey = typeof LeadSource === 'string' ? LeadSource : LeadSource.key
		const LeadSourceLabel = typeof LeadSource === 'string' ? LeadSource : LeadSource.label
		const sourceData = data[LeadSourceKey] || []
		const subSources = LeadSubSources.map(LeadSubSource => {
			const LeadSubSourceKey = typeof LeadSubSource === 'string' ? LeadSubSource : LeadSubSource.key
			const LeadSubSourceLabel = typeof LeadSubSource === 'string' ? LeadSubSource : LeadSubSource.label
			const subSourceData = sourceData.find((item) => item.LeadSubSource === LeadSubSourceKey)
			if (!subSourceData) {
				return getEmptySubSource(LeadSourceKey, LeadSourceLabel, LeadSubSourceKey, LeadSubSourceLabel)
			} else {
				const LeadPurchase = subSourceData[`LeadPurchase_${period}`]
				const LeadCost = subSourceData[`LeadCost_${period}`]
				const CloseRatio = subSourceData.CloseRatio
				const TPCloseRatio = subSourceData.TPCloseRatio
				const isEmpty = [LeadPurchase, LeadCost, CloseRatio, TPCloseRatio].every(checkNullish)
				return { LeadSource, LeadSourceLabel, LeadSubSource, LeadSubSourceLabel, LeadPurchase, LeadCost, CloseRatio, TPCloseRatio, isEmpty }
			}
		})
		qlm.push({ LeadSource: LeadSourceKey, LeadSourceLabel }, ...subSources)
	})
	return qlm
}

export default {
    components: {
		DashCard,
		LeadMarketplaceIcon,
		CalculationDot,
	},
	props: {
		items: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		headers () {
			return [{
                text: 'Lead Source',
                value: 'LeadSubSource',
            }, {
                text: 'Lead Purchase',
                value: 'LeadPurchase',
            }, {
                text: 'Lead Cost',
                value: 'LeadCost',
            }, {
                text: 'Close Ratio',
                value: 'CloseRatio',
            }, {
                text: 'TP\u00A0Close Ratio',
                value: 'TPCloseRatio',
            }]
		},
		loading () {
			if (!this.items) { return false }
			return this.items.loading
		},
		hasError () {
			if (!this.items) { return false }
			return this.items.error
		},
		noLoadedData () {
			if (this.items && this.items.loaded) {
				if (!this.items.data) { return true }
				return !Object.keys(this.items.data).length
			}
			return false
		},
		computedData () {
			if (this.items && this.items.loaded && !this.noLoadedData) {
				return this.monthlyPeriod
					? getQLM('Monthly', this.items.data)
					: getQLM('Weekly', this.items.data)
			}
			return getDefaultData()
		},
    },
	methods: {
		checkNullish,
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0.00')
		},
		formatItem (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return value
		},
		formatPercent (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(100 * value, '0.0')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	--td-padding: 0 0.75rem;

	color: $color-text;

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep .v-data-table__wrapper {
			max-height: 746px;
			overflow: auto;
			padding-right: 5px;

			&::-webkit-scrollbar {
				width: $scrollbar-width-chrome;
				height: $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 16px $color-dark;
				border-radius: 2 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-water-green;
				border-radius: 0.5 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-corner {
				background: transparent;
			}
		}

		&::v-deep tbody tr:hover {
			background: none !important;
		}

		&::v-deep .v-data-table-header {
			position: sticky;
			top: 0;
			background-color: var(--card-bg);
			z-index: 1;

			th {
				position: sticky;
				top: 0;
				background-color: var(--card-bg);
				z-index: 1;
			}
		}

		&::v-deep .v-data-table-header tr th {
			border-bottom: $border-bottom-th;
			padding: var(--td-padding);
			height: 42px !important;
			line-height: 1.3;
			span {
				display: inline-block;
				color: $color-text;
				font-weight: bold;
				max-width: min-content;
			}
		}

		&::v-deep tbody tr {
			td {
				height: 32px !important;
				border-bottom: $border-bottom-td;
				padding: var(--td-padding);
				line-height: 1.3;
				// white-space: nowrap;
			}
			&.summary-row {
				color: $color-water-green;
				font-weight: bold;
				td {
					border-bottom: none !important;
				}
			}
			&.row--empty {
				color: #507B8B;
			}
		}
	}

	.source-title {
		color: $color-water-green;
		font-weight: bold;
	}

	.sub-source-title {
		font-weight: bold;
		background-color: $color-water-green-20;
	}
}
</style>
