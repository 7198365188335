<template>
	<v-dialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="mobileFullscreen && $vuetify.breakpoint.xs"
		:content-class="computedContentClass"
	>
		<v-card class="confirmation-card">
			<v-card-title class="confirmation-card-title">
				<slot name="title">
					<div class="confirmation-card-title__content" v-html="title" />
				</slot>
			</v-card-title>

			<v-card-text class="confirmation-card-text">
				<slot>
					<div class="confirmation-card-text__content" v-html="content" />
				</slot>
			</v-card-text>

			<v-card-actions class="confirmation-card-actions">
				<v-btn class="perform-dash-btn ml-auto px-6" color="#000F2C" outlined @click="computedValue = false">
					No
				</v-btn>
				<v-btn class="perform-dash-btn ml-4 px-6" color="#46C3B2" dark @click="confirm">
					Yes
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Title' },
		content: { type: String, default: 'Content' },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '700px' },
		mobileFullscreen: { type: Boolean, default: false },
		contentClass: { type: String },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedContentClass () {
			return [
				'perform-dash__confirmation-dialog',
				this.isMobileView && 'perform-dash__confirmation-dialog--mobile',
				this.contentClass,
			].filter(Boolean).join(' ')
		},
	},
	methods: {
		confirm () {
			this.$emit('confirm')
			this.computedValue = false
		},
	},
}
</script>

<style lang="scss">
@import '../style/index.scss';

.perform-dash__confirmation-dialog {
	--padding-x: 5rem;
	--padding-y: 3rem;

	&.perform-dash__confirmation-dialog--mobile {
		--padding-x: 1.5rem;
		--padding-y: 1.5rem;
	}

	&:not(.v-dialog--fullscreen) {
		border-radius: $card-border-radius;

		.confirmation-card {
			border-radius: $card-border-radius;
		}
	}

	.confirmation-card {
		position: relative;
	}

	.confirmation-card-title {
		padding: var(--padding-y) var(--padding-x) 0 !important;
	}

	.confirmation-card-title__content {
		flex-grow: 1;
		text-align: center;
		font-size: 22px;
		font-weight: 900 !important;
		letter-spacing: 0.36px;
		color: $color-water-green !important;
	}

	.confirmation-card-text {
		padding: 1.5rem var(--padding-x) 0 !important;
	}

	.confirmation-card-text__content {
		text-align: center;
		font-size: 15px;
		color: #000000 !important;
	}

	.confirmation-card-actions {
		padding: 2.5rem var(--padding-x) var(--padding-y) !important;
	}

	.perform-dash-btn {
		font-weight: 900;
	}
}
</style>
